import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@lushdigital/icons';
import Container from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import colour from '@lushdigital/ui/helpers/colour';
import styled from 'styled-components';

// styled components
const Wrapper = styled.div`
  padding: 80px 20px;
`;

const Tile = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    opacity: 0.4;
  }
`;

const TileIcon = styled.span`
  padding: 22.5px;
  border-radius: 100px;
  background: hsla(0, 0%, 100%, 0.25);
  line-height: 0;
`;

const TileTitle = styled.span`
  display: block;
  padding-top: 20px;
  font-size: 14px;
  color: ${colour.white};
  line-height: 1.6em;
`;

const Tiles = ({ hasPermissions, nav, language }) => {
  return (
    <Wrapper>
      <Container
        style={{
          display: 'flex',
          justifyContent: 'left',
          paddingTop: '40px',
          zIndex: '1'
        }}
      >
        <Row>
          {nav.map(item => {
            const { path, icon, title, permissions: itemPermissions } = item;

            const fullPath = path.replace(':language', language);

            if (!hasPermissions(itemPermissions)) return null;

            return (
              <Column
                key={title}
                medium={3}
                default={6}
                padding="20px"
                style={{ alignItems: 'center', paddingBottom: '60px' }}
              >
                <Tile key={fullPath} href={fullPath}>
                  <TileIcon>
                    <Icon icon={icon} size="55px" fill={colour.white} />
                  </TileIcon>
                  <TileTitle>{title}</TileTitle>
                </Tile>
              </Column>
            );
          })}
        </Row>
      </Container>
    </Wrapper>
  );
};

// prop check
Tiles.propTypes = {
  hasPermissions: PropTypes.func.isRequired,
  nav: PropTypes.array.isRequired,
  language: PropTypes.string.isRequired
};

export default Tiles;
