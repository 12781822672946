import React from 'react';
import { has } from 'lodash';
import { getTokensClient, tokenFullyExpired, tokenExpired, AuthRoute } from '@lushdigital/auth';
import { getEnvRoot, ENV } from '@lushdigital/helper-functions';
import Cookies from 'universal-cookie';

import { refreshToken } from '../helpers/helpers.token';

const cookies = new Cookies();
const domain = getEnvRoot(ENV);

const TokenValidation = props => {
  // GET JWT TOKEN & USER TOKEN
  let tokens = getTokensClient(cookies);

  try {
    tokens = getTokensClient(cookies);
  } catch (e) {
    // If there's an error parsing cookies then try clearing them.
    cookies.remove('jwt', { path: '/', domain });
    cookies.remove('user', { path: '/', domain });
  }

  // GET RID OF EXPIRED TOKENS
  if (has(tokens, 'jwt.ts') && tokenFullyExpired(tokens.jwt.ts)) {
    cookies.remove('jwt', { path: '/', domain });
    cookies.remove('user', { path: '/', domain });
  }

  // CHECK IF TOKEN NEEDS REFRESHING
  React.useEffect(() => {
    if (has(tokens, 'jwt.ts') && !tokenFullyExpired(tokens.jwt.ts) && tokenExpired(tokens.jwt.ts)) {
      /*eslint-disable */
      async function fetchRefreshToken() {
        await refreshToken({ jwt: tokens.jwt });
      }
      fetchRefreshToken();
      /* eslint-enable */
    }
  });

  return <AuthRoute {...props} />;
};

export default TokenValidation;
