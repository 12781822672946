import React from 'react';
import useField from 'bambino-form/useField';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// local
import Row from '@lushdigital/ui/row';
import Label from '@lushdigital/ui/label';
import SubText from '@lushdigital/ui/subText';
import Select from '@lushdigital/ui/select';
import Error from '@lushdigital/ui/error';

// styled
const Wrapper = styled.label`
  display: block;
  width: 100%;
`;

const SelectField = ({
  name,
  placeholder,
  label,
  subtext,
  options,
  value,
  schema,
  optionName,
  optionValue,
  customChangeHandler,
  ...rest
}) => {
  const ref = React.useRef(null);

  const { error, fieldName, defaultValue, onChange, ...bag } = useField({
    name,
    ref,
    schema,
    value
  });

  const [val, setVal] = React.useState(defaultValue || '');

  const selectProps = {
    ...rest,
    ...bag,
    fullWidth: true,
    onChange: e => {
      onChange(e);
      setVal(e.target.value);
      customChangeHandler(e);
    },
    ref
  };

  return (
    <Wrapper htmlFor={selectProps.name}>
      <Label textSize="14px" as="span">
        {label}
      </Label>
      {subtext !== '' && <SubText textSize="14px">{subtext}</SubText>}
      <Row flexWrap="nowrap" alignItems="stretch">
        <Select {...selectProps} value={val}>
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map(option => (
            <option key={option[optionValue]} value={option[optionValue]}>
              {option[optionName]}
            </option>
          ))}
        </Select>
      </Row>
      {error && <Error margin="10px 0 0">{error}</Error>}
    </Wrapper>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  subtext: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  optionName: PropTypes.string,
  optionValue: PropTypes.string,
  schema: PropTypes.any,
  customChangeHandler: PropTypes.func
};

SelectField.defaultProps = {
  subtext: '',
  placeholder: '',
  options: [],
  schema: null,
  value: null,
  optionName: 'name',
  optionValue: 'id',
  customChangeHandler: _ => _
};

SelectField.displayName = 'SelectField';

export default SelectField;
