import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Context } from 'bambino-form';

// ui
import CTA from '@lushdigital/ui/cta';
import UIContainer from '@lushdigital/ui/container';
import Link from '../../components/link';

// helpers
import routes from '../../routes';

const Wrapper = styled.header`
  background: #ffffff;
  border-bottom: 1px solid #eaeaea;
  height: 80px;
  display: flex;
  align-items: center;
`;

const Container = styled(UIContainer)`
  display: flex;
  justify-content: space-between;
`;

// exported component
const Header = ({ isEdit, loading, setModal }) => {
  const { t, i18n } = useTranslation();
  const { handleValidation } = React.useContext(Context);

  const validateOpenModal = async () => {
    const { errors } = await handleValidation();
    if (Object.keys(errors).length === 0) {
      setModal(true);
    }
  };

  return (
    <Wrapper>
      <Container>
        <div>
          {!loading && (
            <CTA
              as={Link}
              to={routes.TRANSLATION_TASKS.path.replace(':language', i18n.language)}
              theme="inverse"
              iconBefore="long-arrow-left"
            >
              {isEdit ? t('EDIT_TRANSLATION_TASK') : t('CREATE_TRANSLATION_TASK')}
            </CTA>
          )}
        </div>
        <CTA type="button" loading={loading} disabled={loading} onClick={() => validateOpenModal()}>
          {t('SAVE_TRANSLATION_TASK')}
        </CTA>
      </Container>
    </Wrapper>
  );
};

Header.defaultProps = {
  language: null,
  isEdit: false
};

Header.propTypes = {
  setModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  language: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string
  })
};

export default React.memo(Header);
