import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '@lushdigital/icons';
import Drop from '@lushdigital/ui/drop';

const ToggleButton = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  -webkit-appearance: none;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const Header = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  background-color: #fff;
  text-align: center;
  z-index: 102;

  .app-switcher__toggle {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .dashboard__bar-header {
    padding: 0px 70px;
    display: block;
    font-size: 14px;
    font-weight: bold;
    color: #000;
  }

  @media (min-width: ${({ breakPoint }) => breakPoint}) {
    display: none;
  }
`;

const Body = styled.div`
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 5px;
  overflow-y: auto;
  z-index: 101;
  -webkit-overflow-scrolling: touch;

  @media (max-width: ${({ breakPoint }) => breakPoint}) {
    padding-top: 70px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }
`;

const Link = styled.a`
  width: calc(33.3333% - 10px);
  margin: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #000;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  text-decoration: none;

  &:before {
    content: '';
    float: left;
    padding-top: 100%;
    display: table;
  }

  span {
    display: block;
  }

  .dashboard__links-link-text {
    font-size: 11px;
    color: #fff;
    text-align: center;
    margin-top: 5px;
    line-height: 1.5;
  }
`;

const Portal = ({ hasPermissions, breakPoint, apps, language, trigger, toggle }) => {
  return (
    <Drop width={420} pointerOffset={10} element={trigger.current} onClose={() => toggle(false)}>
      <Header breakPoint={breakPoint}>
        <ToggleButton onClick={() => toggle(false)} className="app-switcher__toggle">
          <Icon icon="long-arrow-left" size="30px" fill="#000" />
        </ToggleButton>
        <span className="dashboard__bar-header">Lush Apps</span>
      </Header>
      <Body breakPoint={breakPoint} className="dashboard__links">
        {apps.map(app => {
          const { name, href, label, icon, permissions } = app;

          if (hasPermissions(permissions)) {
            return (
              <Link
                key={name}
                breakPoint={breakPoint}
                href={href.replace(':language', language)}
                className="dashboard__links-link"
              >
                <div className="dashboard-navigation__link-link-wrapper">
                  <span className="dashboard-navigation__icon">
                    <Icon icon={icon} size="30px" fill="#fff" />
                  </span>
                  <span className="dashboard__links-link-text">{label}</span>
                </div>
              </Link>
            );
          }
          return null;
        })}
      </Body>
    </Drop>
  );
};

Portal.propTypes = {
  hasPermissions: PropTypes.func.isRequired,
  trigger: PropTypes.any.isRequired,
  toggle: PropTypes.func.isRequired,
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
      permissions: PropTypes.array
    })
  ).isRequired,
  breakPoint: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
};

export default Portal;
