import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// ui
import CTA from '@lushdigital/ui/cta';
import UIContainer from '@lushdigital/ui/container';
import Link from '../../components/link';

// helpers
import routes from '../../routes';

const Wrapper = styled.header`
  background: #ffffff;
  border-bottom: 1px solid #eaeaea;
  height: 80px;
  display: flex;
  align-items: center;
`;

const Container = styled(UIContainer)`
  display: flex;
  justify-content: space-between;
`;

// exported component
const Header = ({ language, loading }) => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <Container>
        <CTA
          as={Link}
          to={routes.LANGUAGES.path.replace(':language', i18n.language)}
          theme="inverse"
          iconBefore="long-arrow-left"
        >
          {`${language.name ? language.name : t('NEW')} | ${t('SETTINGS')}`}
        </CTA>
        <CTA loading={loading} disabled={loading} type="submit">
          {t('SAVE_LANGUAGE')}
        </CTA>
      </Container>
    </Wrapper>
  );
};

Header.defaultProps = {
  language: {}
};

Header.propTypes = {
  loading: PropTypes.bool.isRequired,
  language: PropTypes.shape({
    name: PropTypes.string
  })
};

export default React.memo(Header);
