import React from 'react';
import { useTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import get from 'lodash/get';
import styled from 'styled-components';

import UIContainer from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import Select from '@lushdigital/ui/select';
import Label from '@lushdigital/ui/label';
import Button from '@lushdigital/ui/button';
import Loader from '@lushdigital/ui/loader/loader';
import Checkbox from '@lushdigital/ui/checkboxField';
import P from '@lushdigital/ui/p';

import colour from '@lushdigital/ui/helpers/colour';
import { GET_LANGUAGES } from '../languages/languages.gql';
import { EXPORT } from './export.gql';
import { GET_NAMESPACES } from '../translations/translations.gql';
import { useHeaderDispatch } from '../../components/header/context';

const Container = styled(UIContainer)`
  background: #ffffff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
`;

const Code = styled.pre`
  background-color: #eaeaea;
  font-size: 16px;
  padding: 20px;
`;

const ErrorP = styled(P)`
  color: ${colour.red};
`;

const HiddenInput = styled.textarea`
  position: absolute;
  top: -1000px;
  left: -1000px;
`;

const CopyButton = styled(Button)`
  position: absolute;
  top: 35px;
  right: 30px;
`;

const ExportPage = () => {
  const { t } = useTranslation();
  const [copied, setCopied] = React.useState(false);
  const [language, setLanguage] = React.useState();
  const [context, setContext] = React.useState();
  const [format, setFormat] = React.useState('json');
  const [renderResult, setRenderResult] = React.useState(false);
  const exportField = React.createRef();
  const { setNavigationPage } = useHeaderDispatch();

  React.useEffect(() => {
    setNavigationPage(t('EXPORT'));
  }, []); // eslint-disable-line

  const showExport = language && context && renderResult;

  const copyToClipboard = e => {
    e.preventDefault();

    const isiOS = navigator.userAgent.match(/ipad|iphone/i);
    let range;
    let selection;

    if (isiOS) {
      range = document.createRange();
      range.selectNodeContents(exportField.current);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      exportField.current.setSelectionRange(0, 999999);
    } else {
      exportField.current.select();
    }

    document.execCommand('copy');
    setCopied(true);
  };

  return (
    <Container margin="40px auto" padding="30px 40px">
      <h1>Export</h1>
      <UIContainer maxWidth="740px" margin="auto">
        <Row>
          <Column flexBasis="50%" flexGrow="0" alignItems="stretch">
            <Label>{t('EXPORT_LANGUAGE')}</Label>
            <Query query={GET_LANGUAGES}>
              {({ data }) => {
                const languages = get(data, 'languages', []);

                return (
                  <Select
                    label={t('EXPORT_LANGUAGE')}
                    placeholder={t('EXPORT_LANGUAGE')}
                    name="fallback_language"
                    onChange={e => {
                      setRenderResult(false);
                      setCopied(false);
                      setLanguage(e.target.value);
                    }}
                  >
                    <option value={null}>{t('SELECT_LANGUAGE')}</option>
                    {languages.map(lang => (
                      <option key={lang.id} value={lang.id}>
                        {lang.name}
                      </option>
                    ))}
                  </Select>
                );
              }}
            </Query>
          </Column>
        </Row>
        <Row>
          <Column flexBasis="50%" flexGrow="0" alignItems="stretch">
            <Label>{t('EXPORT_CONTEXT')}</Label>
            <Query query={GET_NAMESPACES}>
              {({ data }) => {
                const contexts = get(data, 'languages_translation_namespaces', []);

                return (
                  <Select
                    label={t('EXPORT_CONTEXT')}
                    placeholder={t('EXPORT_CONTEXT')}
                    name="fallback_language"
                    options={contexts}
                    onChange={e => {
                      setRenderResult(false);
                      setCopied(false);
                      setContext(e.target.value);
                    }}
                  >
                    <option value={null}>{t('SELECT_CONTEXT')}</option>
                    {contexts.map(ctx => (
                      <option key={ctx.id} value={ctx.id}>
                        {ctx.name}
                      </option>
                    ))}
                  </Select>
                );
              }}
            </Query>
          </Column>
        </Row>
        <Column>
          <Label>{t('SELECT_FORMAT')}</Label>
          <Row justifyContent="flex-start">
            <Column flexGrow="0">
              <Checkbox
                type="radio"
                name="format"
                value="json"
                label="JSON"
                checked={format === 'json'}
                onChange={e => {
                  if (e.target.checked) {
                    setRenderResult(false);
                    setCopied(false);
                    setFormat('json');
                  }
                }}
              />
            </Column>
            <Column flexGrow="0">
              <Checkbox
                type="radio"
                name="format"
                value="xml"
                label="XML"
                checked={format === 'xml'}
                onChange={e => {
                  if (e.target.checked) {
                    setRenderResult(false);
                    setCopied(false);
                    setFormat('xml');
                  }
                }}
              />
            </Column>
            <Column>
              <Checkbox
                type="radio"
                name="format"
                value="apple"
                label=".strings"
                checked={format === 'apple'}
                onChange={e => {
                  if (e.target.checked) {
                    setRenderResult(false);
                    setCopied(false);
                    setFormat('apple');
                  }
                }}
              />
            </Column>
          </Row>
        </Column>

        <Column>
          {renderResult && !(context && language) && <ErrorP margin="0 0 20px 0">{t('EXPORT_ERROR')}</ErrorP>}
          <Button onClick={() => setRenderResult(true)}>{t('EXPORT')}</Button>
        </Column>
      </UIContainer>

      {showExport && (
        <UIContainer maxWidth="740px" margin="auto">
          <Row alignItems="stretch">
            <Column alignItems="stretch">
              <Query query={EXPORT} variables={{ language, namespace: context, format }}>
                {({ data, loading, error }) => {
                  if (loading) return <Loader />;
                  if (error) {
                    const message = get(error, 'graphQLErrors[0].extensions.message', 'Something went wrong');
                    return <ErrorP>{message}</ErrorP>;
                  }

                  const exportData = get(data, 'languages_translation_export', '');

                  return (
                    <>
                      <HiddenInput ref={exportField} value={exportData} />
                      <CopyButton theme="default-clear" onClick={copyToClipboard}>
                        {copied ? 'Copied' : 'Copy to clipboard'}
                      </CopyButton>
                      <Code>
                        <code>{exportData}</code>
                      </Code>
                    </>
                  );
                }}
              </Query>
            </Column>
          </Row>
        </UIContainer>
      )}
    </Container>
  );
};

export default ExportPage;
