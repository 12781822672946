import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';

// props designed to filter!
const Link = React.forwardRef((props, ref) => {
  const {
    fontSize,
    textSize,
    align,
    padding,
    margin,
    theme,
    fullWidth,
    circle,
    iconBefore,
    iconAfter,
    showContent,
    label,
    loading,
    iconCircle,
    iconSize,
    iconOffset,
    ...other
  } = props;

  return <ReactRouterLink {...other} ref={ref} />;
});

Link.defaultProps = {
  iconBefore: '',
  iconAfter: '',
  showContent: true,
  label: '',
  loading: false,
  iconCircle: false,
  iconSize: '1.4em',
  iconOffset: '1.8em',
  theme: 'default',
  style: null,
  textSize: '11px',
  fontSize: '11px',
  align: 'center',
  padding: '1.41em 2.4em',
  margin: '0',
  fullWidth: false,
  circle: false
};

Link.propTypes = {
  fontSize: PropTypes.string,
  textSize: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  padding: PropTypes.string,
  margin: PropTypes.string,
  theme: PropTypes.oneOf([
    'default',
    'default-clear',
    'default-solid',
    'default-floating',
    'inverse',
    'inverse-clear',
    'inverse-floating',
    'inverse-solid',
    'error',
    'warning',
    'success'
  ]),
  fullWidth: PropTypes.bool,
  circle: PropTypes.bool,
  showContent: PropTypes.bool,
  iconBefore: PropTypes.string,
  iconAfter: PropTypes.string,
  label: PropTypes.string,
  iconCircle: PropTypes.bool,
  loading: PropTypes.bool,
  iconSize: PropTypes.string,
  iconOffset: PropTypes.string,
  style: PropTypes.object
};

export default Link;
