import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Mutation, Query } from 'react-apollo';
import { Redirect, withRouter } from 'react-router-dom';
import get from 'lodash/get';
import styled from 'styled-components';

// UI
import withAlerts from '@lushdigital/ui/alerts/withAlerts';
import Loader from '@lushdigital/ui/loader';
import Row from '@lushdigital/ui/row';

// components
import ErrorPage from '../../components/errorPage';
import { useHeaderDispatch } from '../../components/header/context';
import Form from './languageSettings.form';
import Error from '../../apollo/apollo.error';

// config
import routes from '../../routes';
import { GET_LANGUAGE, CREATE_LANGUAGE, EDIT_LANGUAGE } from './languageSettings.gql';

const LoadingWrapper = styled(Row)`
  min-height: 400px;
  align-items: center;
`;

const LanguageSettingsPage = ({ insertAlert, match }) => {
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = React.useState(false);
  const { setNavigationPage } = useHeaderDispatch();

  React.useEffect(() => {
    setNavigationPage(t('LANGUAGES'));
  }, []); // eslint-disable-line

  const languageId = match.params.language_id;
  const isEdit = !!languageId;

  if (redirect) {
    return <Redirect to={routes.LANGUAGES.path.replace(':language', i18n.language)} />;
  }

  const handleSubmit = action => async values => {
    const variables = { ...values };
    if (isEdit) variables.id = languageId;

    try {
      await action({ variables });
      insertAlert({
        type: 'success',
        message: isEdit
          ? t('SUCCESSFULLY_UPDATED', { name: values.name })
          : t('SUCCESSFULLY_CREATED', { name: values.name }),
        time: 2
      });
      setRedirect(true);
    } catch (error) {
      insertAlert({
        type: 'error',
        message: get(error, 'graphQLErrors[0].extensions.message', error.message),
        time: 2
      });
    }
  };

  return (
    <Mutation mutation={isEdit ? EDIT_LANGUAGE : CREATE_LANGUAGE}>
      {(action, { loading: saving }) => (
        <Query skip={!isEdit} query={GET_LANGUAGE} fetchPolicy="network-only" variables={{ id: languageId }}>
          {({ data, error, loading }) => {
            if (loading)
              return (
                <LoadingWrapper>
                  <Loader />
                </LoadingWrapper>
              );

            if (error) {
              return <ErrorPage error={error} />;
            }

            const initialValues = get(data, 'languages_get', {});
            return (
              <>
                {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
                <Form onSubmit={handleSubmit(action)} initialValues={initialValues} isEdit={isEdit} loading={saving} />
              </>
            );
          }}
        </Query>
      )}
    </Mutation>
  );
};

LanguageSettingsPage.propTypes = {
  insertAlert: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired
};

export default withAlerts(withRouter(LanguageSettingsPage));
