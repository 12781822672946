import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// UI
import SecondaryHeader from '@lushdigital/ui/secondaryHeader';
import Search from '@lushdigital/ui/search';
import UIContainer from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import CTA from '@lushdigital/ui/cta';
import { AuthWrapper } from '@lushdigital/auth';
import Link from '../../components/link';

// config
import routes from '../../routes';
import GROUPS from '../../config/config.groups.json';

const Wrapper = styled.header`
  background: #ffffff;
  border-bottom: 1px solid #eaeaea;
  height: 80px;
  display: flex;
  align-items: center;
`;

const Container = styled(UIContainer)`
  display: flex;
  justify-content: space-between;
`;

// exported component
const Header = ({ updateSearch }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Wrapper>
        <Container>
          <CTA
            as={Link}
            to={routes.LANGUAGES.path.replace(':language', i18n.language)}
            theme="inverse"
            padding="1.41em 2.4em 1.41em 1em"
            iconBefore="long-arrow-left"
          >
            {t('TRANSLATION_TASKS')}
          </CTA>
        </Container>
      </Wrapper>

      <SecondaryHeader>
        <Row alignItems="center">
          <Column>
            <Search onSearch={updateSearch} />
          </Column>
          <AuthWrapper permissions={[GROUPS.LANGUAGE_MANAGER.ADMIN]}>
            <CTA as={Link} to={routes.NEW_TRANSLATION_TASK.path.replace(':language', i18n.language)}>
              {t('CREATE_NEW_TRANSLATION_TASK')}
            </CTA>
          </AuthWrapper>
        </Row>
      </SecondaryHeader>
    </>
  );
};

Header.propTypes = {
  updateSearch: PropTypes.func.isRequired
};

export default Header;
