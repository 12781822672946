import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

//
import ErrorPage from '../../components/errorPage';

//
const withErrorPage = WrappedComponent => {
  class ErrorWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = { error: null };
    }

    componentDidCatch(error, errorInfo) {
      try {
        Sentry.withScope(scope => {
          Object.keys(errorInfo).forEach(key => {
            scope.setExtra(key, errorInfo[key]);
          });
          Sentry.captureException(error);
        });
      } catch (e) {
        console.error('Lush app error', e);
      }
      this.setState({ error });
    }

    render() {
      const { error } = this.state;

      if (error) {
        return <ErrorPage error={error} />;
      }
      return <WrappedComponent {...this.props} />;
    }
  }

  return ErrorWrapper;
};

export default withErrorPage;
