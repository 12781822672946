import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import get from 'lodash/get';

// local ui
import Error from '../../apollo/apollo.error';

// schema
import { GET_NAMESPACES } from './translationTasks.gql';

// components
import Filter from '../../components/filter';

// exported component
const NamespaceSelector = ({ namespace, setNamespace }) => {
  const { t } = useTranslation();

  return (
    <Query query={GET_NAMESPACES}>
      {({ loading, error, data }) => {
        const options = get(data, 'languages_translation_namespaces', []);

        return (
          <>
            {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
            <Filter
              label={t('CONTEXT')}
              name="namespaces"
              onChange={e => setNamespace(e.target.value)}
              options={options}
              value={namespace}
              disabled={loading || error}
            />
          </>
        );
      }}
    </Query>
  );
};

NamespaceSelector.propTypes = {
  namespace: PropTypes.string.isRequired,
  setNamespace: PropTypes.func.isRequired
};

export default NamespaceSelector;
