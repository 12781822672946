import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

// UI
import Container from '@lushdigital/ui/container';
import Card from '@lushdigital/ui/card';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import Icon from '@lushdigital/icons';
import Pagination from '@lushdigital/ui/pagination';

// components
import Header from './translations.header';
import { useHeaderDispatch } from '../../components/header/context';
import TranslationsTable from './translations.table';
import Error from '../../apollo/apollo.error';
import NamespaceSelector from './translations.namespaceSelector';
import StatusSelector from './translations.statusSelector';
import PrioritySelector from './translations.prioritySelector';

// config
import { GET_TRANSLATIONS } from './translations.gql';
import { useSearchFilterContext } from '../../contexts/searchFilter';

const Translations = ({ match }) => {
  const { t } = useTranslation();
  const { status, namespace, search, priority, page, setPage } = useSearchFilterContext();
  const { setNavigationPage } = useHeaderDispatch();

  const languageId = match.params.language_id;

  React.useEffect(() => {
    setNavigationPage(t('TRANSLATIONS'));
  }, []); // eslint-disable-line

  return (
    <>
      <Header />
      <Container margin="60px auto 80px">
        <Container margin="0 auto 20px">
          <Row justifyContent="flex-start">
            <PrioritySelector />
            <NamespaceSelector />
            <StatusSelector />
          </Row>
        </Container>
        <Query
          query={GET_TRANSLATIONS}
          variables={{ language: languageId, namespace, status, priority, query: search, page, fallback: true }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            const translations = get(data, 'languages_translations.translations', []);
            const pagination = get(data, 'languages_translations.pagination', {});

            return (
              <>
                <Card>
                  {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
                  <TranslationsTable loading={loading} translations={translations} languageId={languageId} />
                </Card>
                {!loading && pagination && (
                  <Row>
                    <Column padding="20px">
                      <Pagination
                        total={pagination.total}
                        page={pagination.current_page}
                        pagesToShow={5}
                        perPage={pagination.per_page}
                        changePage={num => () => setPage(num)}
                        previous={<Icon icon="long-arrow-left" size="14px" styles={{ marginRight: '10px' }} />}
                        next={<Icon icon="long-arrow-right" size="14px" styles={{ marginLeft: '10px' }} />}
                      />
                    </Column>
                  </Row>
                )}
              </>
            );
          }}
        </Query>
      </Container>
    </>
  );
};

Translations.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired
};

export default withRouter(Translations);
