// language management routes
import Languages from '../pages/languages';
import LanguageSettings from '../pages/languageSettings';

// transation management routes
import Translations from '../pages/translations';
import Translation from '../pages/translation';

// task management routes
import TranslationTasks from '../pages/translationTasks';
import TranslationTask from '../pages/translationTask';

// misc routes
import Login from '../pages/login';
import Import from '../pages/import';
import Export from '../pages/export';
import NotFound from '../components/notFound';
import NotAuthorized from '../components/notAuthorized';

// config
import routes from './index';
import GROUPS from '../config/config.groups.json';

// base url
const BASE = '/:language';

// exported
const routesConfig = [
  {
    exact: true,
    component: Languages,
    authenticated: true,
    ...routes.LANGUAGES
  },
  {
    exact: true,
    component: LanguageSettings,
    authenticated: true,
    path: routes.NEW_LANGUAGE.path,
    title: 'NEW_LANGUAGE',
    permissions: GROUPS.LANGUAGE_MANAGER.CREATOR_PLUS
  },
  {
    exact: true,
    component: LanguageSettings,
    authenticated: true,
    path: routes.EDIT_LANGUAGE.path,
    title: 'EDIT_LANGUAGE',
    permissions: GROUPS.LANGUAGE_MANAGER.CREATOR_PLUS
  },

  {
    exact: true,
    component: Translations,
    authenticated: true,
    path: routes.LANGUAGE_TRANSLATIONS.path,
    title: 'TRANSLATIONS',
    permissions: GROUPS.LANGUAGE_MANAGER.ALL
  },
  {
    exact: true,
    component: Translation,
    authenticated: true,
    path: routes.EDIT_TRANSLATION.path,
    title: 'EDIT_TRANSLATION',
    permissions: GROUPS.LANGUAGE_MANAGER.ALL
  },

  {
    exact: true,
    component: TranslationTasks,
    authenticated: true,
    ...routes.TRANSLATION_TASKS
  },
  {
    exact: true,
    component: TranslationTask,
    authenticated: true,
    path: routes.NEW_TRANSLATION_TASK.path,
    title: 'TRANSLATION_TASK',
    permissions: GROUPS.LANGUAGE_MANAGER.CREATOR_PLUS
  },
  {
    exact: true,
    component: TranslationTask,
    authenticated: true,
    path: routes.EDIT_TRANSLATION_TASK.path,
    title: 'TRANSLATION_TASK',
    permissions: GROUPS.LANGUAGE_MANAGER.ALL
  },

  {
    exact: true,
    component: Login,
    authenticated: false,
    notAuthenticated: true,
    path: `${BASE}/login`,
    title: 'Login',
    icon: 'login'
  },
  {
    exact: true,
    component: Export,
    authenticated: true,
    notAuthenticated: false,
    ...routes.EXPORT
  },
  {
    exact: true,
    component: Import,
    authenticated: true,
    notAuthenticated: false,
    ...routes.IMPORT
  },
  {
    component: NotAuthorized,
    authenticated: true,
    path: `${BASE}/401`,
    title: '401'
  },
  {
    // Must be last!
    component: NotFound,
    authenticated: true,
    path: `${BASE}/*`,
    title: '404'
  }
];

export default routesConfig;
