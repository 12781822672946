import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import Filter from '../../components/filter';

// exported component
const PrioritySelector = ({ priority, setPriority }) => {
  const { t } = useTranslation();
  const options = [{ id: 'normal', name: t('NORMAL_PRIORITY') }, { id: 'high', name: t('HIGH_PRIORITY') }];

  return (
    <Filter
      label={t('PRIORITY')}
      name="priority"
      onChange={e => setPriority(e.target.value)}
      options={options}
      value={priority}
    />
  );
};

PrioritySelector.propTypes = {
  priority: PropTypes.string.isRequired,
  setPriority: PropTypes.func.isRequired
};

export default PrioritySelector;
