import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// ui
import FancyTable from '@lushdigital/ui/fancyTable';
import Row from '@lushdigital/ui/row';
import Title from '@lushdigital/ui/title';
import Column from '@lushdigital/ui/column';
import Loader from '@lushdigital/ui/loader';
import { InlineMenu, InlineMenuLink } from '@lushdigital/ui/inlineMenu';
import colour from '@lushdigital/ui/helpers/colour';
import { formatDate, formatFullDate } from '../../helpers/helpers.date';

import routes from '../../routes';

const PriorityBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${colour.red};
  height: 81px;
  width: 5px;
`;

const CopyText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${({ priority }) => (priority ? 'bold' : 'normal')};
  color: ${({ subtle }) => (subtle ? colour.black25 : colour.charcoal)};
`;

const StatusText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ color }) => colour[color]};
  font-weight: bold;
  text-align: right;
`;

const Abbr = styled.abbr`
  border: none !important;
  text-decoration: none !important;
`;

const Priority = ({ data }) => {
  if (data.priority !== 'high') return null;
  return <PriorityBar />;
};
Priority.defaultProps = { data: {} };
Priority.propTypes = {
  data: PropTypes.shape({ priority: PropTypes.string })
};

const Copy = ({ data }) => {
  if (!data.copy) {
    return (
      <CopyText subtle priority={data.priority === 'high'}>
        {data.master_copy || '-'}
      </CopyText>
    );
  }
  return <CopyText priority={data.priority === 'high'}>{data.copy || '-'}</CopyText>;
};
Copy.defaultProps = { data: {} };
Copy.propTypes = {
  data: PropTypes.shape({
    master_copy: PropTypes.string,
    copy: PropTypes.string,
    priority: PropTypes.string
  })
};

const Type = ({ data }) => {
  const { t } = useTranslation();
  const types = { ui: t('UI'), content: t('CONTENT') };
  return <strong>{types[data.type]}</strong>;
};
Type.defaultProps = { data: {} };
Type.propTypes = {
  data: PropTypes.shape({ type: PropTypes.string })
};

const LastUpdated = ({ data }) => {
  if (data.priority === 'high') {
    return (
      <strong>
        <Abbr title={formatFullDate(data.updated_at)}>{formatDate(data.updated_at)}</Abbr>
      </strong>
    );
  }
  return (
    <span>
      <Abbr title={formatFullDate(data.updated_at)}>{formatDate(data.updated_at)}</Abbr>
    </span>
  );
};
LastUpdated.defaultProps = { data: {} };
LastUpdated.propTypes = {
  data: PropTypes.shape({
    updated_at: PropTypes.string,
    priority: PropTypes.string
  })
};

const Namespace = ({ data }) => {
  if (data.priority === 'high') {
    return <strong>{data.namespace}</strong>;
  }
  return <span>{data.namespace}</span>;
};
Namespace.defaultProps = { data: {} };
Namespace.propTypes = {
  data: PropTypes.shape({
    namespace: PropTypes.string,
    priority: PropTypes.string
  })
};

const Status = ({ data }) => {
  const { t } = useTranslation();
  switch (data.status) {
    case 'completed':
      return <StatusText color="green">{t('COMPLETED')}</StatusText>;
    case 'in_progress':
      return <StatusText color="amber">{t('IN_PROGRESS')}</StatusText>;
    default:
      return <StatusText>{data.status}</StatusText>;
  }
};
Status.defaultProps = { data: {} };
Status.propTypes = {
  data: PropTypes.shape({ status: PropTypes.string })
};

// exported component
const TranslationsTable = ({ languageId, translations, loading, query }) => {
  const { t, i18n } = useTranslation();

  const inlineMenu = {
    cell: cellProps => {
      const { data } = cellProps;
      return (
        <InlineMenu context="table">
          <InlineMenuLink
            icon="edit"
            title={t('edit')}
            as={Link}
            link={routes.EDIT_TRANSLATION.path
              .replace(':language', i18n.language)
              .replace(':language_id', languageId)
              .replace(':translation_task_id', data.id)}
          />
        </InlineMenu>
      );
    },
    minWidth: '80px'
  };

  return (
    <>
      <FancyTable
        columns={[
          {
            head: '',
            cell: <Priority />,
            minWidth: '0',
            flexGrow: '0',
            flexBasis: '0'
          },
          {
            head: t('TYPE'),
            cell: <Type />,
            minWidth: '100px',
            flexGrow: '0',
            flexBasis: '100px'
          },
          {
            head: t('COPY'),
            cell: <Copy />,
            minWidth: '380px',
            flexGrow: '1',
            flexShrink: '1'
          },
          {
            head: t('CONTEXT'),
            cell: <Namespace />,
            minWidth: '200px',
            flexGrow: '1',
            flexShrink: '1'
          },
          {
            head: t('LAST_UPDATED'),
            cell: <LastUpdated />,
            minWidth: '140px',
            flexGrow: '1'
          },
          {
            head: '',
            cell: <Status />,
            minWidth: '140px',
            flexGrow: '1'
          }
        ]}
        data={loading ? [] : translations}
        actions={inlineMenu}
      />
      {loading && <Loader />}
      {!loading && !translations.length && (
        <Row>
          <Column padding="40px">
            <Title>{query ? t('NO_RESULTS_FOUND', { query }) : t('NO_RECORDS')}</Title>
          </Column>
        </Row>
      )}
    </>
  );
};

TranslationsTable.defaultProps = {
  query: ''
};

TranslationsTable.propTypes = {
  translations: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  query: PropTypes.string,
  languageId: PropTypes.string.isRequired
};

export default TranslationsTable;
