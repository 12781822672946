import React from 'react';
import PropTypes from 'prop-types';

// styles
import { Title } from './header.styles';

// Exported component
const AppName = ({ appName }) => <Title>{appName}</Title>;

// prop check
AppName.propTypes = {
  appName: PropTypes.string.isRequired
};

// export
export default React.memo(AppName);
