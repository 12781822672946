import gql from 'graphql-tag';

export const CREATE_LANGUAGE = gql`
  mutation languages_create($name: String!, $id: String!, $fallback_language: String!) {
    languages_create(name: $name, id: $id, fallback_language: $fallback_language) {
      name
      id
    }
  }
`;

export const EDIT_LANGUAGE = gql`
  mutation languages_edit($name: String!, $id: String!, $fallback_language: String!) {
    languages_edit(name: $name, id: $id, fallback_language: $fallback_language) {
      name
      id
      fallback_language
    }
  }
`;

export const GET_LANGUAGE = gql`
  query languages_get($id: String!) {
    languages_get(id: $id) {
      name
      id
      fallback_language
    }
  }
`;
