import React from 'react';
import PropTypes from 'prop-types';

// ui
import Avatar from '@lushdigital/ui/avatar';
import Button from '@lushdigital/ui/button';
import Drop from '@lushdigital/ui/drop';

// styles
import { AppUserWrapper, AppUserLabel, AppUserList } from './header.styles';

// Exported component
const AppUser = ({ user, logout, accountRoute, labels }) => {
  const [open, setOpen] = React.useState(false);
  const labelRef = React.useRef(null);

  return (
    <AppUserWrapper ref={labelRef}>
      <AppUserLabel onClick={() => setOpen(current => !current)}>
        <Avatar user={user} />
      </AppUserLabel>
      {open && (
        <Drop fixed={false} element={labelRef.current} onClose={() => setOpen(false)}>
          <AppUserList>
            <Button as="a" key="my-account" theme="inverse" fullWidth href={accountRoute}>
              {labels.MY_ACCOUNT}
            </Button>
            <Button key="logout" fullWidth onClick={logout}>
              {labels.LOGOUT}
            </Button>
          </AppUserList>
        </Drop>
      )}
    </AppUserWrapper>
  );
};

// prop check
AppUser.propTypes = {
  logout: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired,
  accountRoute: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

// export
export default React.memo(AppUser);
