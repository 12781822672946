import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHeaderDispatch } from '../../components/header/context';

import View from './translationTask';

const TranslationTask = () => {
  const { t } = useTranslation();
  const { setNavigationPage } = useHeaderDispatch();

  React.useEffect(() => {
    setNavigationPage(t('TRANSLATION_TASKS'));
  }, []); // eslint-disable-line

  return <View />;
};

export default TranslationTask;
