import React from 'react';
import { Login as LoginForm } from '@lushdigital/auth';
import { ENV, appEnvironmentRoutes } from '@lushdigital/helper-functions';
import { useTranslation } from 'react-i18next';

//
const appRoutes = appEnvironmentRoutes(ENV);

// component
const Login = () => {
  const { i18n } = useTranslation();

  const handleLogin = () => {
    // const returnUrl = parse(location.search).return;
    const url = appRoutes.LANGUAGE_MANAGER.replace(':language', i18n.language);
    window.location.replace(url);
  };

  return (
    <>
      <LoginForm callback={handleLogin} />
    </>
  );
};

export default Login;
