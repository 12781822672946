import React from 'react';
import PropTypes from 'prop-types';

// components
import AppNav from './header.AppNav';
import AppName from './header.AppName';
import AppSwitcher from './header.AppSwitcher';
import AppUser from './header.AppUser';

// styles
import { Container, Wrapper, Menu, Controls } from './header.styles';

// component
const Header = ({ accountRoute, logout, hasPermissions, language, labels, appNav, appName, appSwitcher, appUser }) => {
  const hasAppUser = Object.entries(appUser).length > 0 && appUser.constructor === Object;
  const hasAppSwitcher = appSwitcher.length > 0;
  const hasAppNav = appNav.length > 0;

  return (
    <Container>
      <Wrapper>
        <Menu>
          {hasAppNav && <AppNav hasPermissions={hasPermissions} nav={appNav} language={language} />}
          {appName && <AppName appName={appName} />}
          {(hasAppSwitcher || hasAppUser) && (
            <Controls>
              {hasAppSwitcher && (
                <AppSwitcher hasPermissions={hasPermissions} labels={labels} language={language} apps={appSwitcher} />
              )}
              {hasAppUser && <AppUser logout={logout} labels={labels} accountRoute={accountRoute} user={appUser} />}
            </Controls>
          )}
        </Menu>
      </Wrapper>
    </Container>
  );
};

Header.defaultProps = {
  language: 'en',
  accountRoute: '/account',
  appNav: [],
  appSwitcher: [],
  appUser: {},
  labels: {
    APP_SWITCHER: 'App switcher',
    MY_ACCOUNT: 'My account',
    LOGOUT: 'Logout'
  }
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  hasPermissions: PropTypes.func.isRequired,
  language: PropTypes.string,
  accountRoute: PropTypes.string,
  labels: PropTypes.object,

  //
  appSwitcher: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired
    })
  ),
  appNav: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.number).isRequired
    })
  ),
  appName: PropTypes.string.isRequired,
  appUser: PropTypes.object
};

export default Header;
