import gql from 'graphql-tag';

export const GET_LANGUAGES = gql`
  query languages($query: String) {
    languages(query: $query) {
      name
      id
      updated_at
      high_priority_count
      completed_ui
      completed_content
    }
  }
`;
