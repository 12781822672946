import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// UI
import colour from '@lushdigital/ui/helpers/colour';
import Column from '@lushdigital/ui/column';
import Row from '@lushdigital/ui/row';
import ActionCard from '@lushdigital/ui/actionCard';

// components
import Menu from './languages.menu';

// helpers
import { formatDate } from '../../helpers/helpers.date';

const CardColumn = styled(Column)`
  div[class^='card__Card'] > div:not([class]) {
    width: 100%;
  }
`;

const CardHeader = styled.header`
  border-bottom: 1px solid ${colour.lightgray};
  position: relative;
  width: 100%;
`;

const CardHeading = styled.h2`
  font-size: 17px;
`;

const CardTitle = styled.h3`
  font-size: 12px;
  color: ${colour.darkgray};
  font-weight: 400;
  margin: 0;
`;

const CardText = styled.p`
  font-size: 14px;
  margin: 0;
`;

const ToolTip = styled.div`
  display: none;
  position: absolute;
  right: -10px;
  top: calc(100% - 10px);
  background-color: white;
  padding: 0.5em 1em;
  max-width: 200px;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  line-height: 1.3;
`;

const Badge = styled.span`
  font-size: 13px;
  background-color: ${colour.red};
  color: ${colour.white};
  padding: 0.2em 0.8em;
  border-radius: 20px;
  user-select: none;
  &:hover + ${ToolTip} {
    display: block;
  }
`;

const Card = ({ language }) => {
  const { t } = useTranslation();

  return (
    <CardColumn key={language.id} tiny={6} medium={3} padding="10px">
      <ActionCard theme="success" actions={() => <Menu language={language} />}>
        <Row>
          <CardHeader>
            <Row justifyContent="space-between" alignItems="center">
              <Column padding="0px 10px">
                <CardHeading>{language.name}</CardHeading>
              </Column>
              {!!language.high_priority_count && (
                <Column tiny={3} padding="0 10px 0 0" alignItems="flex-end">
                  <Badge>{language.high_priority_count}</Badge>
                  <ToolTip>
                    {language.high_priority_count === 1
                      ? t('HIGH_PRIORITY_REMAINING_SINGLE')
                      : t('HIGH_PRIORITY_REMAINING', { count: language.high_priority_count })}
                  </ToolTip>
                </Column>
              )}
            </Row>
          </CardHeader>
        </Row>
        <Row>
          <Column padding="20px 2px 20px 10px">
            <CardTitle>{t('UI_TRANSLATIONS')}</CardTitle>
            <CardText>{language.completed_ui}</CardText>
          </Column>
          <Column padding="20px 10px 20px 2px">
            <CardTitle>{t('CONTENT_TRANSLATIONS')}</CardTitle>
            <CardText>{language.completed_content}</CardText>
          </Column>
        </Row>
        <Row>
          <Column padding="20px 2px 20px 10px">
            <CardTitle>{t('LAST_MODIFIED')}</CardTitle>
            <CardText>{formatDate(language.updated_at)}</CardText>
          </Column>
        </Row>
      </ActionCard>
    </CardColumn>
  );
};

Card.propTypes = {
  language: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    completed_ui: PropTypes.string.isRequired,
    completed_content: PropTypes.string.isRequired,
    high_priority_count: PropTypes.number.isRequired,
    updated_at: PropTypes.string
  }).isRequired
};

export default Card;
