import React from 'react';
import useField from 'bambino-form/useField';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// local
import Text from '@lushdigital/ui/label';
import Caption from '@lushdigital/ui/caption';

const Wrapper = styled.div`
  margin: 0 0 10px;
`;

const Checkbox = styled.input`
  position: absolute;
  left: -9999px;
`;

const Label = styled.label`
  position: relative;
  display: flex;
  font-size: 14px;
  line-height: 2;
  font-weight: 500;
  color: #000;
  width: 100%;
`;

const Tick = styled.div`
  display: block;
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 3px;
  transition: all 0.2s ease;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 3px 5px;
  margin-right: 15px;
  cursor: pointer;

  *:focus > ${Label} &,
  *:active > ${Label} &,
  *:focus + ${Label} &,
  *:active + ${Label} & {
    box-shadow: inset 0 0 0 0.2em rgba(0, 123, 255, 0.75);
    border-color: #80bdff;
    outline: none;
  }

  width: 30px;
  height: 30px;
  background-size: 30px;
  background-position: 5px 7px;

  ${Checkbox}:checked + label & {
    background-color: #000;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTE5IDIyOEwzNDYgMWwyOSAzMC0yNTYgMjU2TDAgMTY4bDMwLTMwIDg5IDkweiIvPjwvc3ZnPg==');
  }
`;

const FormError = styled.span`
  font-size: 14px;
  border-top: 2px solid #d0021b;
  margin-top: 10px;
  display: inline-block;
  color: #d0021b;
  padding-top: 5px;
`;

const Subtext = styled(Caption)`
  display: block;
`;

const CheckboxField = ({ name, schema, value, label, subText, customChangeHandler, ...rest }) => {
  const ref = React.useRef(null);

  // custom behaviour and logic from useField
  const { error, fieldName, onChange, defaultValue, ...bag } = useField({
    name,
    ref,
    schema,
    value,
    path: 'checked'
  });

  // props for check element
  const checkProps = {
    ...rest,
    ...bag,
    type: 'checkbox',
    defaultChecked: defaultValue,
    onChange: e => {
      onChange(e);
      customChangeHandler(e);
    },
    ref
  };

  return (
    <Wrapper>
      <Checkbox {...checkProps} />
      <Label htmlFor={checkProps.name}>
        <Tick />
        <div>
          <Text as="span" textSize="14px" margin="0.3em 0 0">
            {label}
          </Text>
          {subText !== '' && (
            <Subtext textSize="14px" as="span" margin="5px 0 0">
              {subText}
            </Subtext>
          )}
        </div>
      </Label>
      {error && <FormError margin="10px 0 0">{error}</FormError>}
    </Wrapper>
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  subText: PropTypes.string,
  schema: PropTypes.any,
  value: PropTypes.bool,
  customChangeHandler: PropTypes.func
};

CheckboxField.defaultProps = {
  subText: '',
  placeholder: '',
  schema: null,
  value: false,
  customChangeHandler: _ => _
};

CheckboxField.displayName = 'CheckboxField';

export default CheckboxField;
