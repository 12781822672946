import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@lushdigital/icons';

// styles
import { AppSwitcherWrapper, AppSwitcherToggle } from './header.styles';

// components
import AppSwitcherPortal from './header.AppSwitcherPortal';

// Exported component
const AppSwitcher = ({ hasPermissions, language, labels, apps }) => {
  const [open, setOpen] = React.useState(false);
  const trigger = React.useRef(null);

  return (
    <AppSwitcherWrapper>
      <AppSwitcherToggle ref={trigger} onClick={() => setOpen(current => !current)} aria-label={labels.APP_SWITCHER}>
        <Icon icon="grid" size="21px" fill="#fff" />
      </AppSwitcherToggle>
      {open && (
        <AppSwitcherPortal
          trigger={trigger}
          toggle={setOpen}
          breakPoint="53.75em"
          hasPermissions={hasPermissions}
          apps={apps}
          language={language}
        />
      )}
    </AppSwitcherWrapper>
  );
};

// prop check
AppSwitcher.propTypes = {
  apps: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
  hasPermissions: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired
};

// export
export default React.memo(AppSwitcher);
