import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Mutation, Query } from 'react-apollo';
import get from 'lodash/get';
import styled from 'styled-components';

import UIContainer from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import Select from '@lushdigital/ui/select';
import Label from '@lushdigital/ui/label';
import Button from '@lushdigital/ui/button';
import TextArea from '@lushdigital/ui/textArea';
import withAlerts from '@lushdigital/ui/alerts/withAlerts';

import { useHeaderDispatch } from '../../components/header/context';
import Error from '../../apollo/apollo.error';
import { GET_LANGUAGES } from '../languages/languages.gql';
import { IMPORT } from './import.gql';
import { GET_NAMESPACES } from '../translations/translations.gql';

const Container = styled(UIContainer)`
  background: #ffffff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
`;

const Textarea = styled(TextArea)`
  font-family: 'Courier 10 Pitch', Courier, monospace;
  font-size: 14px;
  line-height: 140%;
  white-space: pre;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
`;

const ImportPage = ({ insertAlert }) => {
  const { t } = useTranslation();
  const [language, setLanguage] = React.useState();
  const [context, setContext] = React.useState();
  const [translations, setTranslations] = React.useState();
  const { setNavigationPage } = useHeaderDispatch();

  React.useEffect(() => {
    setNavigationPage(t('IMPORT'));
  }, []); // eslint-disable-line

  const disabled = context === undefined || language === undefined || translations === undefined || translations === '';

  const handleSubmit = ({ action }) => {
    try {
      action({
        variables: {
          language,
          namespace: context,
          translationObject: JSON.stringify(JSON.parse(translations))
        }
      });
    } catch (error) {
      insertAlert({
        type: 'error',
        message: 'Poorly formated JSON. Please double check import data',
        time: 4
      });
    }
  };

  return (
    <Mutation
      mutation={IMPORT}
      onCompleted={() => {
        insertAlert({
          type: 'success',
          message: 'You did it! 🥇',
          time: 2
        });
      }}
    >
      {(action, { error }) => (
        <Container margin="40px auto" padding="30px 40px">
          {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
          <h1>Import</h1>
          <UIContainer maxWidth="740px" margin="auto">
            {/* language */}
            <Row>
              <Column flexBasis="50%" flexGrow="0" alignItems="stretch">
                <Label>{t('IMPORT_LANGUAGE')}</Label>
                <Query query={GET_LANGUAGES}>
                  {({ data }) => {
                    const languages = get(data, 'languages', []);

                    return (
                      <Select
                        label={t('IMPORT_LANGUAGE')}
                        placeholder={t('IMPORT_LANGUAGE')}
                        name="fallback_language"
                        onChange={e => setLanguage(e.target.value)}
                      >
                        <option value={null}>{t('SELECT_LANGUAGE')}</option>
                        {languages.map(lang => (
                          <option key={lang.id} value={lang.id}>
                            {lang.name}
                          </option>
                        ))}
                      </Select>
                    );
                  }}
                </Query>
              </Column>
            </Row>
            {/* context */}
            <Row>
              <Column flexBasis="50%" flexGrow="0" alignItems="stretch">
                <Label>{t('IMPORT_CONTEXT')}</Label>
                <Query query={GET_NAMESPACES}>
                  {({ data }) => {
                    const contexts = get(data, 'languages_translation_namespaces', []);

                    return (
                      <Select
                        label={t('IMPORT_CONTEXT')}
                        placeholder={t('IMPORT_CONTEXT')}
                        name="fallback_language"
                        options={contexts}
                        onChange={e => setContext(e.target.value)}
                      >
                        <option value={null}>{t('SELECT_CONTEXT')}</option>
                        {contexts.map(ctx => (
                          <option key={ctx.id} value={ctx.id}>
                            {ctx.name}
                          </option>
                        ))}
                      </Select>
                    );
                  }}
                </Query>
              </Column>
            </Row>
            {/* code */}
            <Row>
              <Column flexBasis="100%" flexGrow="0" alignItems="stretch">
                <Label>{t('IMPORT_JSON')}</Label>
                <Textarea
                  maxLength={1000000}
                  rows={10}
                  value={translations}
                  onChange={e => setTranslations(e.target.value)}
                />
              </Column>
            </Row>
            {/* submit */}
            <Column>
              <Button disabled={disabled} onClick={() => handleSubmit({ action })}>
                {t('IMPORT')}
              </Button>
            </Column>
          </UIContainer>
        </Container>
      )}
    </Mutation>
  );
};

ImportPage.propTypes = {
  insertAlert: PropTypes.func.isRequired
};

export default withAlerts(ImportPage);
