import React from 'react';
import { useTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import get from 'lodash/get';

// UI
import Container from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import Loader from '@lushdigital/ui/loader';
import Title from '@lushdigital/ui/title';

// components
import { useHeaderDispatch } from '../../components/header/context';
import Error from '../../apollo/apollo.error';
import Card from './languages.card';
import Header from './languages.header';

// config
import { GET_LANGUAGES } from './languages.gql';

const LanguagesPage = () => {
  const { t } = useTranslation();
  const [query, setQuery] = React.useState('');
  const { setNavigationPage } = useHeaderDispatch();

  React.useEffect(() => {
    setNavigationPage(t('LANGUAGES'));
  }, []); // eslint-disable-line

  return (
    <>
      <Header setQuery={setQuery} />
      <Query fetchPolicy="network-only" query={GET_LANGUAGES} variables={{ query }}>
        {({ data, loading, error }) => {
          const languages = get(data, 'languages', []);
          return (
            <Container margin="60px auto 80px">
              {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}

              <Row flexWrap="wrap">
                {languages.map(language => (
                  <Card key={language.id} language={language} />
                ))}
              </Row>

              {loading && (
                <Row>
                  <Column padding="40px">
                    <Loader />
                  </Column>
                </Row>
              )}

              {!loading && !languages.length && (
                <Row>
                  <Column padding="40px">
                    <Title>{query ? t('NO_RESULTS_FOUND', { query }) : t('NO_RECORDS')}</Title>
                  </Column>
                </Row>
              )}
            </Container>
          );
        }}
      </Query>
    </>
  );
};

export default LanguagesPage;
