import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { Context as FormContext } from 'bambino-form';

// UI
import UiModal from '@lushdigital/ui/modal';
import Button from '@lushdigital/ui/button';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import Loader from '@lushdigital/ui/loader';

//
import InputField from '../../components/input';
import SelectField from '../../components/select';
import CheckboxField from '../../components/checkbox';

// config
import userList from '../../config/config.notification';

// exported component
const Modal = ({ onClose, saving }) => {
  const { t } = useTranslation();
  const { getFields, handleSubmit } = React.useContext(FormContext);

  const { data } = getFields();

  const options = [{ id: 'enabled', name: t('ENABLED') }, { id: 'disabled', name: t('DISABLED') }];

  return (
    <UiModal
      header={t('SAVE_TRANSLATION_TASK')}
      width="440px"
      closeOnEsc={!saving}
      closeOnBackdrop={!saving}
      hideClose={saving}
      onClose={onClose}
    >
      <Row>
        <Column>
          <SelectField
            label={t('STATUS')}
            placeholder={t('STATUS')}
            schema={string().required(t('STATUS_IS_REQUIRED'))}
            name="status"
            options={options}
            disabled={saving}
          />
        </Column>
      </Row>

      <Row>
        <Column>
          {/* optional message */}
          <CheckboxField name="notify" disabled={saving} label={t('NOTIFY_TEAM')} />
        </Column>
      </Row>

      {data.notify && (
        <>
          <Row>
            <Column>
              <SelectField
                label={t('RECIPIENT')}
                placeholder={t('SELECT_RECIPIENT')}
                schema={string().required(t('RECIPIENT_ARE_REQUIRED'))}
                name="to"
                options={userList(t)}
                disabled={saving}
              />
            </Column>
          </Row>

          <Row>
            <Column>
              <InputField
                label={t('MESSAGE')}
                placeholder={t('ENTER_MESSAGE')}
                name="message"
                schema={string().required(t('MESSAGE_IS_REQUIRED'))}
                fullWidth
                disabled={saving}
                multiline
              />
            </Column>
          </Row>
        </>
      )}

      {/* submit button */}
      <Row padding="20px 0 0">
        <Column>
          <Button disabled={saving} fullWidth type="submit" onClick={handleSubmit}>
            {!saving && t('SAVE_TRANSLATION')}
            {saving && <Loader size="1.5em" theme="white" />}
          </Button>
        </Column>
      </Row>
    </UiModal>
  );
};

Modal.propTypes = {
  saving: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Modal;
