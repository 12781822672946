import styled from 'styled-components';

// ###### //
// header //
// ###### //
export const Container = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  height: 80px;
`;

export const Wrapper = styled.div`
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 10px;
`;

export const Menu = styled.nav`
  position: relative;
  display: grid;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 1100px;
  height: 80px;
  margin: 0 auto;
  padding: 0 10px;

  /* display: flex;
  align-items: center;
  justify-content: space-between; */

  grid-template-areas: 'AppNav AppName AppUser';
  grid-template-columns: 1fr 1fr 1fr;
`;

export const Title = styled.span`
  grid-area: AppName;
  justify-self: center;

  z-index: 2;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 0;
  text-decoration: none;
  display: none;
  font-weight: 500;
  margin: 0;

  @media (min-width: 420px) {
    display: block;
  }
`;

export const Controls = styled.div`
  grid-area: AppUser;
  justify-self: flex-end;

  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

// ############# //
// header.AppNav //
// ############# //
export const AppNavToggle = styled.button`
  grid-area: AppNav;
  justify-self: flex-start;

  display: block;
  z-index: 2;
  outline: none;
  background: none;
  position: relative;
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 2.5px;
  padding-left: 32px;
  cursor: pointer;
  color: #fff;

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const AppNavWrapper = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  background: #000;
  overflow-y: scroll;
  z-index: 1;
`;

// ################### //
// ./header.AppSwitcher //
// ################### //
export const AppSwitcherWrapper = styled.div`
  line-height: 0;
`;

export const AppSwitcherToggle = styled.button`
  line-height: 0;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  -webkit-appearance: none;
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

// ################ //
// ./header.AppUser //
// ################ //
export const AppUserWrapper = styled.div`
  position: relative;
  margin-left: 12px;
`;

export const AppUserLabel = styled.button`
  background: none;
  -webkit-appearance: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
`;

export const AppUserList = styled.div`
  width: 200px;
  background: #fff;
  z-index: 5;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
`;
