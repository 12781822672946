import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AuthWrapper } from '@lushdigital/auth';

// UI
import CTA from '@lushdigital/ui/cta';

import Link from '../../components/link';

// config
import GROUPS from '../../config/config.groups.json';
import routes from '../../routes';

const Menu = ({ language }) => {
  const { t, i18n } = useTranslation();

  return [
    <AuthWrapper permissions={[GROUPS.LANGUAGE_MANAGER.ADMIN]} key="manage">
      <CTA
        theme="inverse"
        iconCircle
        iconAfter="settings"
        title={t('MANAGE_SETTINGS')}
        as={Link}
        to={routes.EDIT_LANGUAGE.path.replace(':language', i18n.language).replace(':language_id', language.id)}
      >
        {t('MANAGE_SETTINGS')}
      </CTA>
    </AuthWrapper>,
    <CTA
      theme="inverse"
      iconCircle
      iconAfter="translate"
      key="translations"
      title={t('VIEW_TRANSLATIONS')}
      as={Link}
      to={routes.LANGUAGE_TRANSLATIONS.path.replace(':language', i18n.language).replace(':language_id', language.id)}
    >
      {t('VIEW_TRANSLATIONS')}
    </CTA>
  ];
};

Menu.propTypes = {
  language: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default Menu;
