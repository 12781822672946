import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { string } from 'yup';
import { Form as BambinoForm } from 'bambino-form';
import styled from 'styled-components';
import get from 'lodash/get';

// UI
import UIContainer from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';

// components
import Header from './languageSettings.header';
import Input from '../../components/input';
import Select from '../../components/select';

// config
import { GET_LANGUAGES } from '../languages/languages.gql';

const Container = styled(UIContainer)`
  background: #ffffff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
`;

const Form = ({ onSubmit, initialValues, isEdit, loading }) => {
  const { t } = useTranslation();

  return (
    <BambinoForm onSubmit={onSubmit} initialValues={initialValues}>
      <Header language={initialValues} loading={loading} />
      <Container margin="40px auto" padding="30px 40px">
        <h1>{isEdit ? t('EDIT_LANGUAGE') : t('NEW_LANGUAGE')}</h1>
        <UIContainer maxWidth="740px" margin="auto">
          <Row alignItems="stretch">
            <Column alignItems="stretch">
              <Row>
                <Column>
                  <Input
                    label={t('NAME')}
                    placeholder={t('ENTER_NAME')}
                    name="name"
                    schema={string().required(t('NAME_IS_REQUIRED'))}
                    fullWidth
                  />
                </Column>
              </Row>
              <Row>
                <Column tiny={6}>
                  <Input
                    label={t('CODE')}
                    placeholder={t('ENTER_LANGUAGE_CODE')}
                    name="id"
                    disabled={isEdit}
                    schema={string()
                      .matches(/^[a-zA-Z-]+$/, {
                        message: t('INCORRECT_FORMAT_CODE'),
                        excludeEmptyString: true
                      })
                      .required(t('CODE_IS_REQUIRED'))}
                  />
                </Column>
              </Row>
              <Row>
                <Column tiny={6}>
                  <Query query={GET_LANGUAGES}>
                    {({ data }) => {
                      const languages = get(data, 'languages', []);

                      return (
                        <Select
                          label={t('FALLBACK_LANGUAGE')}
                          placeholder={t('SELECT_FALLBACK_LANGUAGE')}
                          schema={string().required(t('FALL_BACK_LANGUAGE_IS_REQUIRED'))}
                          name="fallback_language"
                          options={languages}
                        />
                      );
                    }}
                  </Query>
                </Column>
              </Row>
            </Column>
          </Row>
        </UIContainer>
      </Container>
    </BambinoForm>
  );
};

Form.defaultProps = {
  initialValues: {}
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

export default Form;
