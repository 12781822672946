import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Selector from '@lushdigital/ui/selector';

// styled ui
const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 40px;
`;

const Label = styled.label`
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 13px;
  padding-right: 1em;
  padding-right: 0.5em;
`;

// exported component
const Filter = ({ onChange, value, options, label, name, disabled }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Label htmlFor={name}>{label}:</Label>
      <Selector
        label={label}
        hideLabel
        placeholder={t('ALL')}
        placeholderDisabled={false}
        name={name}
        onChange={e => onChange(e)}
        value={value}
        options={options}
        labelKey="name"
        valueKey="id"
        disabled={disabled}
        styles={{ fontSize: '14px', padding: '0 0.6em 0 0' }}
      />
    </Container>
  );
};

Filter.defaultProps = {
  disabled: false
};

Filter.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default Filter;
