import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Mutation, Query } from 'react-apollo';
import get from 'lodash/get';
import styled from 'styled-components';

// UI
import Loader from '@lushdigital/ui/loader';
import Row from '@lushdigital/ui/row';
import withAlerts from '@lushdigital/ui/alerts/withAlerts';

// components
import ErrorPage from '../../components/errorPage';
import Error from '../../apollo/apollo.error';
import Form from './translationTask.form';

// config
import routes from '../../routes';
import { truncateString } from '../../helpers/helpers.strings';

// GQL
import { CREATE_TRANSLATION_TASK, EDIT_TRANSLATION_TASK, GET_TRANSLATION_TASK } from './translationTask.gql';

const LoadingWrapper = styled(Row)`
  min-height: 400px;
  align-items: center;
`;

const TranslationTaskPage = ({ match, insertAlert }) => {
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = React.useState(false);
  const translationTaskId = match.params.translation_task_id;
  const isEdit = !!translationTaskId;

  const handleSubmit = action => async values => {
    const variables = { ...values };

    if (isEdit) variables.id = translationTaskId;

    try {
      await action({
        variables: {
          ...variables,
          interfaceLanguage: i18n.language
        }
      });

      insertAlert({
        type: 'success',
        message: isEdit
          ? t('SUCCESSFULLY_UPDATED', { name: truncateString(values.master_copy) })
          : t('SUCCESSFULLY_CREATED', { name: truncateString(values.master_copy) }),
        time: 2
      });
      setRedirect(true);
    } catch (error) {
      insertAlert({
        type: 'error',
        message: get(error, 'graphQLErrors[0].extensions.message', error.message),
        time: 2
      });
    }
  };

  if (redirect) {
    return <Redirect to={routes.TRANSLATION_TASKS.path.replace(':language', i18n.language)} />;
  }

  return (
    <Mutation mutation={isEdit ? EDIT_TRANSLATION_TASK : CREATE_TRANSLATION_TASK}>
      {(action, { loading: saving }) => (
        <Query skip={!isEdit} query={GET_TRANSLATION_TASK} variables={{ id: translationTaskId }}>
          {({ data, error, loading }) => {
            if (loading) {
              return (
                <LoadingWrapper>
                  <Loader />
                </LoadingWrapper>
              );
            }

            if (error) {
              return <ErrorPage error={error} />;
            }

            const initialValues = get(data, 'languages_translation_task', {});
            initialValues.priority = initialValues.priority === 'high';

            return (
              <>
                {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
                <Form
                  onSubmit={handleSubmit(action, initialValues)}
                  initialValues={initialValues}
                  isEdit={isEdit}
                  loading={saving}
                />
              </>
            );
          }}
        </Query>
      )}
    </Mutation>
  );
};

TranslationTaskPage.propTypes = {
  insertAlert: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired
};

export default withRouter(withAlerts(TranslationTaskPage));
