export const formatDate = timestamp => {
  if (!timestamp) return '-';
  const date = parseInt(timestamp, 10);

  return new Date(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

export const formatFullDate = timestamp => {
  if (!timestamp) return '-';
  const date = parseInt(timestamp, 10);

  return new Date(date).toLocaleDateString(undefined, {
    minute: '2-digit',
    hour: '2-digit',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};
