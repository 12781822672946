import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPage from '../errorPage';

const NotAuthorized = () => {
  const { t } = useTranslation();
  return <ErrorPage error={{ message: t('NOT_AUTHORIZED'), status: 401 }} />;
};

export default NotAuthorized;
