import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPage from '../errorPage';

const NotFound = () => {
  const { t } = useTranslation();
  return <ErrorPage error={{ message: t('NOT_FOUND'), status: 404 }} />;
};

export default NotFound;
