import { getEnvironment } from '@lushdigital/helper-functions';

const userList = t => {
  if (getEnvironment() === 'production') {
    return [{ id: 'translators', name: t('TRANSLATORS') }, { id: 'rachel.king@lush.co.uk', name: 'Rachel King' }];
  }
  return [
    { id: 'translators', name: t('TRANSLATORS') },
    { id: 'rachel.king@lush.co.uk', name: 'Rachel King' },
    { id: 'james.bliss@lush.co.uk', name: 'James Bliss' },
    { id: 'ben.honeywill@lush.co.uk', name: 'Ben Honeywill' },
    { id: 'fed.team@lush.co.uk', name: 'FED Team' },
    { id: 'cheryl.greenhalgh@lush.co.uk', name: 'Cheryl Greenhalgh' },
    { id: 'george.tarkalanov@lush.co.uk', name: 'George Tarkalanov' },
    { id: 'ukqa@lush.co.uk', name: 'QA Team' }
  ];
};

export default userList;
