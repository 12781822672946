import gql from 'graphql-tag';

export const GET_TRANSLATIONS = gql`
  query languages_translations(
    $language: String!
    $namespace: String
    $status: String
    $priority: String
    $query: String
    $page: Int!
    $per_page: Int
  ) {
    languages_translations(
      language: $language
      namespace: $namespace
      status: $status
      priority: $priority
      query: $query
      page: $page
      per_page: $per_page
    ) {
      translations {
        master_copy
        namespace
        machine_name
        type
        id
        description
        copy(language: $language)
        status(language: $language)
        priority(language: $language)
        updated_at(language: $language)
        created_at(language: $language)
      }
      pagination {
        total
        per_page
        current_page
        last_page
        next_page
        prev_page
      }
    }
  }
`;

export const GET_NAMESPACES = gql`
  query languages_translation_namespaces {
    languages_translation_namespaces {
      name
      id
    }
  }
`;
