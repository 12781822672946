import gql from 'graphql-tag';

export const MANAGE_TRANSLATION = gql`
  mutation languages_manage_translation(
    $id: String!
    $language: String!
    $namespace: String!
    $status: String!
    $copy: String!
    $priority: Boolean!
    $message: String
    $to: String
    $interfaceLanguage: String
  ) {
    languages_manage_translation(
      id: $id
      language: $language
      namespace: $namespace
      status: $status
      copy: $copy
      priority: $priority
      message: $message
      to: $to
      interfaceLanguage: $interfaceLanguage
    ) {
      master_copy
      namespace
      machine_name
      type
      id
      description
      priority(language: $language)
      copy(language: $language)
      status(language: $language)
      updated_at(language: $language)
      created_at(language: $language)
    }
  }
`;

export const GET_TRANSLATION = gql`
  query languages_translation($id: String!, $language: String!) {
    languages_translation(id: $id, language: $language) {
      master_copy
      namespace
      machine_name
      type
      id
      description
      priority(language: $language)
      copy(language: $language)
      status(language: $language)
      updated_at(language: $language)
      created_at(language: $language)
    }
  }
`;

export const LANGUAGES_TRANSLATED_FOR_TASK = gql`
  query languages_translated_for_task($task_id: String!) {
    languages_translated_for_task(task_id: $task_id) {
      name
      id
      copy
    }
  }
`;
