/**
 * Simple exmaple function to return a string
 * @param  {String} something Random string
 * @return {String}           Return the random string
 */
export const exampleHelper = something => {
  return something;
};

export function sanitize(value) {
  return value.replace(/<[^>]+>/g, '');
}
