import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Container from '@lushdigital/ui/container';

const Pre = styled.pre`
  padding: 20px;
  background-color: #eaeaea;
  overflow-x: auto;
  font-size: 14px;
  max-height: 500px;
`;

const Wrapper = styled.div`
  text-align: center;
  padding: 60px 0;
`;

const Status = styled.h1`
  font-size: 75px;
  font-weight: 900;
  margin: 0;
`;

const Message = styled.p`
  font-size: 24px;
  margin: 0;
`;

const ErrorPage = ({ error }) => {
  const { t } = useTranslation();

  let message = t('THERE_WAS_AN_ERROR');
  let status = t('WHOOPS');

  if (error.message) {
    message = error.message;
  }

  if (error.status) {
    status = error.status;
  }

  if (error.graphQLErrors) {
    message = get(error, 'graphQLErrors[0].extensions.message', message);
    status = get(error, 'graphQLErrors[0].extensions.status', status);
  }

  if (error.networkError) {
    message = t('NETWORK_ERROR');
  }

  return (
    <Container>
      <Wrapper>
        <Status>{status}</Status>
        <Message>{message}</Message>
      </Wrapper>

      {process.env.NODE_ENV === 'development' && (
        <Pre>
          <code>{JSON.stringify(error, null, 2)}</code>
        </Pre>
      )}
    </Container>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object.isRequired
};

export default ErrorPage;
