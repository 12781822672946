import gql from 'graphql-tag';

export const GET_TRANSLATION_TASK = gql`
  query languages_translation_task($id: String!) {
    languages_translation_task(id: $id) {
      namespace
      id
      description
      machine_name
      type
      status
      priority
      master_copy
    }
  }
`;

export const LANGUAGES_TRANSLATED_FOR_TASK = gql`
  query languages_translated_for_task($task_id: String!) {
    languages_translated_for_task(task_id: $task_id) {
      name
      id
      copy
    }
  }
`;

export const CREATE_TRANSLATION_TASK = gql`
  mutation languages_create_translation_task(
    $description: String!
    $machine_name: String!
    $namespace: String!
    $type: String!
    $status: String!
    $priority: Boolean!
    $master_copy: String!
    $message: String
    $to: String
    $interfaceLanguage: String
  ) {
    languages_create_translation_task(
      description: $description
      machine_name: $machine_name
      namespace: $namespace
      status: $status
      priority: $priority
      type: $type
      master_copy: $master_copy
      message: $message
      to: $to
      interfaceLanguage: $interfaceLanguage
    ) {
      namespace
      id
      description
      machine_name
      type
      status
      priority
      updated_at
      created_at
      master_copy
    }
  }
`;

export const EDIT_TRANSLATION_TASK = gql`
  mutation languages_edit_translation_task(
    $id: String!
    $description: String!
    $machine_name: String!
    $namespace: String!
    $type: String!
    $status: String!
    $priority: Boolean!
    $master_copy: String!
    $message: String
    $to: String
    $interfaceLanguage: String
  ) {
    languages_edit_translation_task(
      id: $id
      description: $description
      machine_name: $machine_name
      namespace: $namespace
      status: $status
      priority: $priority
      type: $type
      master_copy: $master_copy
      message: $message
      to: $to
      interfaceLanguage: $interfaceLanguage
    ) {
      namespace
      id
      description
      machine_name
      type
      status
      priority
      updated_at
      created_at
      master_copy
    }
  }
`;
