import gql from 'graphql-tag';

export const GET_TRANSLATION_TASKS = gql`
  query languages_translation_tasks(
    $namespace: String
    $status: String
    $priority: String
    $query: String
    $page: Int!
    $per_page: Int
  ) {
    languages_translation_tasks(
      namespace: $namespace
      priority: $priority
      status: $status
      query: $query
      page: $page
      per_page: $per_page
    ) {
      translations {
        namespace
        id
        description
        machine_name
        type
        priority
        master_copy
        status
        updated_at
      }
      pagination {
        total
        per_page
        current_page
        last_page
        next_page
        prev_page
      }
    }
  }
`;

export const GET_NAMESPACES = gql`
  query languages_translation_namespaces {
    languages_translation_namespaces {
      name
      id
    }
  }
`;
