import React from 'react';
import { withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import styled from 'styled-components';

import Loader from '@lushdigital/ui/loader';
import Row from '@lushdigital/ui/row';
import { withAuth } from '@lushdigital/auth';

export const lockContext = React.createContext({});

const GET_LOCK = gql`
  query languages_translation_lock($id: String!) {
    languages_translation_lock(id: $id) {
      user_id
      language
    }
  }
`;

const SET_LOCK = gql`
  mutation languages_translation_set_lock($id: String!, $language: String!) {
    languages_translation_set_lock(id: $id, language: $language) {
      user_id
      language
    }
  }
`;

const REMOVE_LOCK = gql`
  mutation languages_translation_remove_lock($id: String!) {
    languages_translation_remove_lock(id: $id)
  }
`;

const LoadingWrapper = styled(Row)`
  min-height: 400px;
  align-items: center;
`;

const Provider = ({ translationTaskId, client, children, language, user }) => {
  const [initialized, setInitialized] = React.useState(false);
  const [lock, setLock] = React.useState({});

  React.useEffect(() => {
    if (!translationTaskId) {
      setInitialized(true);
      return _ => _;
    }

    // Function to get the current lock for the translation
    const getLock = async () => {
      const { data } = await client.query({
        fetchPolicy: 'no-cache',
        query: GET_LOCK,
        variables: { id: translationTaskId }
      });
      return data.languages_translation_lock;
    };

    // Function to remove the current lock for the translation
    const removeLock = async () => {
      return client.mutate({
        mutation: REMOVE_LOCK,
        variables: { id: translationTaskId }
      });
    };

    // If the window is closed then remove the lock
    window.addEventListener('beforeunload', removeLock);

    // First... see f a lock already exists for the translation
    getLock().then(async data => {
      if (data) {
        // If it does, store the lock in state
        setLock(data);
      } else {
        // Otherwise, set a new lock
        const { data: setData } = await client.mutate({
          mutation: SET_LOCK,
          variables: { id: translationTaskId, language }
        });

        setLock(setData.languages_translation_set_lock);
      }

      // The lock is now initialized so we can render children
      setInitialized(true);
    });

    // When the component unmounts remove the lock
    return () => {
      window.removeEventListener('beforeunload', removeLock);
      if (lock.user_id === user.id) {
        removeLock();
      }
    };
  }, [client, language, lock.user_id, translationTaskId, user.id]);

  // The lock should only be passed to the children if it is locked by another user
  const clientLock = lock && lock.user_id !== user.id ? lock : null;

  if (!initialized) {
    return (
      <LoadingWrapper>
        <Loader />
      </LoadingWrapper>
    );
  }
  return <lockContext.Provider value={{ lock: clientLock }}>{children}</lockContext.Provider>;
};

Provider.defaultProps = {
  translationTaskId: null
};

Provider.propTypes = {
  children: PropTypes.node.isRequired,
  client: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  translationTaskId: PropTypes.string,
  language: PropTypes.string.isRequired
};

export const LockProvider = withAuth(withApollo(Provider));
