import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@lushdigital/icons';

// context
import { useHeaderState, useHeaderDispatch } from './context';

// styles
import { AppNavToggle, AppNavWrapper } from './header.styles';

// components
import Tiles from './header.AppNavTiles';

// Exported component
const AppNav = ({ hasPermissions, nav, language }) => {
  const { menuOpen, icon, currentPage, text } = useHeaderState();
  const { setNavigationState } = useHeaderDispatch();
  const [visibility, setVisibility] = React.useState(false);

  React.useEffect(() => {
    setNavigationState({
      menuOpen: visibility,
      icon: visibility ? 'close' : 'menu',
      text: visibility ? 'MENU' : currentPage
    });
  }, [visibility, menuOpen]); // eslint-disable-line

  return (
    <>
      <AppNavToggle open={menuOpen} onClick={() => setVisibility(current => !current)}>
        <Icon icon={icon} size={icon === 'menu' ? '19px' : '13px'} fill="#fff" />
        {text}
      </AppNavToggle>
      {menuOpen && (
        <AppNavWrapper>
          <Tiles hasPermissions={hasPermissions} nav={nav} language={language} />
        </AppNavWrapper>
      )}
    </>
  );
};

// prop check
AppNav.propTypes = {
  hasPermissions: PropTypes.func.isRequired,
  nav: PropTypes.array.isRequired,
  language: PropTypes.string.isRequired
};

// export
export default AppNav;
