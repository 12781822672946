import React from 'react';
import { useTranslation } from 'react-i18next';

// context
import { useSearchFilterContext } from '../../contexts/searchFilter';

// components
import Filter from '../../components/filter';

// exported component
const PrioritySelector = () => {
  const { t } = useTranslation();
  const { priority, updateSearchFilterContext } = useSearchFilterContext();
  const options = [{ id: 'normal', name: t('NORMAL_PRIORITY') }, { id: 'high', name: t('HIGH_PRIORITY') }];

  return (
    <Filter
      label={t('PRIORITY')}
      name="priority"
      onChange={e => updateSearchFilterContext({ priority: e.target.value })}
      options={options}
      value={priority}
    />
  );
};
export default PrioritySelector;
