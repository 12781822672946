// config
import GROUPS from '../config/config.groups.json';

// base url
const BASE = '/:language';

// exported
const routes = {
  LANGUAGES: {
    path: BASE,
    title: 'LANGUAGES',
    permissions: GROUPS.LANGUAGE_MANAGER.ALL
  },
  NEW_LANGUAGE: {
    path: `${BASE}/new`
  },
  EDIT_LANGUAGE: {
    path: `${BASE}/edit/:language_id`
  },

  LANGUAGE_TRANSLATIONS: {
    path: `${BASE}/translations/:language_id`
  },
  NEW_TRANSLATION: {
    path: `${BASE}/translations/:language_id/new`
  },
  EDIT_TRANSLATION: {
    path: `${BASE}/translations/:language_id/edit/:translation_task_id`
  },

  TRANSLATION_TASKS: {
    path: `${BASE}/translation-tasks`,
    title: 'TRANSLATION_TASKS',
    permissions: GROUPS.LANGUAGE_MANAGER.CREATOR_PLUS
  },
  NEW_TRANSLATION_TASK: {
    path: `${BASE}/translation-tasks/new`
  },
  EDIT_TRANSLATION_TASK: {
    path: `${BASE}/translation-tasks/edit/:translation_task_id`
  },

  EXPORT: {
    title: 'EXPORT',
    path: `${BASE}/export`,
    permissions: [GROUPS.LANGUAGE_MANAGER.ADMIN]
  },
  IMPORT: {
    title: 'IMPORT',
    path: `${BASE}/import`,
    permissions: [GROUPS.LANGUAGE_MANAGER.ADMIN]
  }
};

export default routes;
