import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

//
import Modal from '@lushdigital/ui/modal';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import P from '@lushdigital/ui/p';
import CTA from '@lushdigital/ui/cta';

//
import Link from '../link';

//
import routes from '../../routes';

const LockModal = ({ lock, match }) => {
  const { t, i18n } = useTranslation();
  if (!lock) return null;

  const languageId = match.params.language_id;

  return (
    <Modal
      header={t('EDITING_LOCKED')}
      width="440px"
      padding={false}
      hideClose
      closeOnEsc={false}
      closeOnBackdrop={false}
      onClose={_ => _}
    >
      <Column padding="40px 40px 20px 40px" flexDirection="column" justifyContent="center">
        <P align="center">{t('LANGUAGE_LOCK')}</P>
      </Column>
      <Row padding="20px 40px 40px 40px" flexDirection="column" alignItems="center">
        <CTA
          as={Link}
          fullWidth
          to={routes.LANGUAGE_TRANSLATIONS.path.replace(':language', i18n.language).replace(':language_id', languageId)}
        >
          {t('GO_BACK')}
        </CTA>
      </Row>
    </Modal>
  );
};

LockModal.defaultProps = {
  lock: null
};

LockModal.propTypes = {
  lock: PropTypes.object,
  match: PropTypes.object.isRequired
};

export default withRouter(LockModal);
