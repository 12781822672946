import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const searchFilterContext = React.createContext({});
searchFilterContext.displayName = 'searchFilterContext';

export const useSearchFilterContext = () => {
  const context = React.useContext(searchFilterContext);
  if (Object.keys(context).length === 0) {
    throw new Error('useSearchFilterContext must be used within a searchFilterContext');
  }
  return context;
};

export const SearchFilterProvider = withRouter(({ children, location }) => {
  const [search, setSearch] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [priority, setPriority] = React.useState('');
  const [namespace, setNamespace] = React.useState('');
  const [page, setPage] = React.useState(1);

  const { i18n } = useTranslation();

  const updateSearchFilterContext = React.useCallback(
    (newFilters = {}) => {
      const filters = {
        search,
        status,
        priority,
        namespace,
        page: 1,
        ...newFilters
      };
      setSearch(filters.search);
      setStatus(filters.status);
      setPriority(filters.priority);
      setNamespace(filters.namespace);
      setPage(filters.page);
    },
    [search, status, priority, namespace]
  );

  React.useEffect(() => {
    if (!location.pathname.startsWith(`/${i18n.language}/translations/`)) {
      updateSearchFilterContext({ search: '', status: '', priority: '', namespace: '' });
    }
  }, [location, i18n.language, updateSearchFilterContext]);

  return (
    <searchFilterContext.Provider
      value={{
        search,
        status,
        priority,
        namespace,
        page,
        setPage,
        updateSearchFilterContext
      }}
    >
      {children}
    </searchFilterContext.Provider>
  );
});

SearchFilterProvider.displayName = 'SearchFilterProvider';

SearchFilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};
