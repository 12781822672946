import React from 'react';
import PropTypes from 'prop-types';

// initial context creation
const HeaderStateContext = React.createContext();
HeaderStateContext.displayName = 'HeaderStateContext';
const HeaderDispatchContext = React.createContext();
HeaderDispatchContext.displayName = 'HeaderDispatchContext';

//
function HeaderProvider({ children }) {
  const [navigation, setNavigation] = React.useState({
    text: 'Menu',
    menuOpen: false,
    icon: 'menu',
    currentPage: 'LUSH'
  });

  const setNavigationPage = currentPage => {
    setNavigation(current => {
      return {
        ...current,
        text: currentPage,
        currentPage
      };
    });
  };

  const setNavigationState = action => {
    setNavigation(current => {
      return {
        ...current,
        text: action.text,
        menuOpen: action.menuOpen,
        icon: action.icon
      };
    });
  };

  return (
    <HeaderStateContext.Provider value={navigation}>
      <HeaderDispatchContext.Provider value={{ setNavigationPage, setNavigationState }}>
        {children}
      </HeaderDispatchContext.Provider>
    </HeaderStateContext.Provider>
  );
}

// prop checks
HeaderProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
HeaderProvider.displayName = 'HeaderProvider';

//
function useHeaderState() {
  const context = React.useContext(HeaderStateContext);
  if (context === undefined) {
    throw new Error('useHeaderState must be used within a HeaderProvider');
  }
  return context;
}

//
function useHeaderDispatch() {
  const context = React.useContext(HeaderDispatchContext);
  if (context === undefined) {
    throw new Error('useHeaderDispatch must be used within a HeaderProvider');
  }
  return context;
}

// export
export { HeaderProvider, useHeaderState, useHeaderDispatch };
