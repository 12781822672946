import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AuthWrapper } from '@lushdigital/auth';

//

// UI
import SecondaryHeader from '@lushdigital/ui/secondaryHeader';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import Search from '@lushdigital/ui/search';
import CTA from '@lushdigital/ui/cta';

import Link from '../../components/link';

//
import GROUPS from '../../config/config.groups.json';

// config
import routes from '../../routes';

const Header = ({ setQuery }) => {
  const { t } = useTranslation();

  return (
    <SecondaryHeader>
      <Row alignItems="center">
        <Column>
          <Search
            onSearch={string => {
              setQuery(string);
            }}
          />
        </Column>
        <AuthWrapper permissions={[GROUPS.LANGUAGE_MANAGER.ADMIN]}>
          <CTA as={Link} to={routes.NEW_LANGUAGE.path}>
            {t('CREATE_NEW_LANGUAGE')}
          </CTA>
        </AuthWrapper>
      </Row>
    </SecondaryHeader>
  );
};

Header.propTypes = {
  setQuery: PropTypes.func.isRequired
};

export default Header;
