import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { Query } from 'react-apollo';

// UI
import SecondaryHeader from '@lushdigital/ui/secondaryHeader';
import Search from '@lushdigital/ui/search';
import UIContainer from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import CTA from '@lushdigital/ui/cta';
import Link from '../../components/link';
import { useSearchFilterContext } from '../../contexts/searchFilter';

// config
import routes from '../../routes';
import { GET_LANGUAGE } from '../languageSettings/languageSettings.gql';

const Wrapper = styled.header`
  background: #ffffff;
  border-bottom: 1px solid #eaeaea;
  height: 80px;
  display: flex;
  align-items: center;
`;

const Container = styled(UIContainer)`
  display: flex;
  justify-content: space-between;
`;

// exported component
const Header = ({ match }) => {
  const { t, i18n } = useTranslation();
  const { search, updateSearchFilterContext } = useSearchFilterContext();

  const languageId = match.params.language_id;

  return (
    <>
      <Wrapper>
        <Container>
          <Query query={GET_LANGUAGE} variables={{ id: languageId }}>
            {({ data }) => (
              <CTA
                as={Link}
                to={routes.LANGUAGES.path.replace(':language', i18n.language)}
                theme="inverse"
                padding="1.41em 2.4em 1.41em 1em"
                iconBefore="long-arrow-left"
              >
                {`${get(data, 'languages_get.name', '')} | ${t('TRANSLATIONS')}`}
              </CTA>
            )}
          </Query>
        </Container>
      </Wrapper>

      <SecondaryHeader>
        <Row alignItems="center">
          <Column>
            <Search onSearch={term => updateSearchFilterContext({ search: term })} defaultValue={search} />
          </Column>
        </Row>
      </SecondaryHeader>
    </>
  );
};

Header.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired
};

export default withRouter(Header);
