import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHeaderDispatch } from '../../components/header/context';

import View from './translations';

const Translations = () => {
  const { t } = useTranslation();
  const { setNavigationPage } = useHeaderDispatch();

  React.useEffect(() => {
    setNavigationPage(t('TRANSLATIONS'));
  }, []); // eslint-disable-line

  return <View />;
};

export default Translations;
