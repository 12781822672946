import React from 'react';
import { useTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import get from 'lodash/get';

// UI
import Container from '@lushdigital/ui/container';
import Card from '@lushdigital/ui/card';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';
import Icon from '@lushdigital/icons';
import Pagination from '@lushdigital/ui/pagination';

// components
import Header from './translationTasks.header';
import { useHeaderDispatch } from '../../components/header/context';
import TranslationsTable from './translationTasks.table';
import Error from '../../apollo/apollo.error';
import NamespaceSelector from './translationTasks.namespaceSelector';
import StatusSelector from './translationTasks.statusSelector';
import PrioritySelector from './translationTasks.prioritySelector';

// config
import { GET_TRANSLATION_TASKS } from './translationTasks.gql';

const TranslationsPage = () => {
  const { t } = useTranslation();
  const { setNavigationPage } = useHeaderDispatch();
  const [query, setQuery] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [namespace, setNamespace] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [priority, setPriority] = React.useState('');

  React.useEffect(() => {
    setNavigationPage(t('TRANSLATION_TASKS'));
  }, []); // eslint-disable-line

  const updateSearch = term => {
    setQuery(term);
    setPage(1);
  };

  return (
    <>
      <Header updateSearch={updateSearch} />
      <Container margin="60px auto 80px">
        <Container margin="0 auto 20px">
          <Row>
            <PrioritySelector setPriority={setPriority} priority={priority} />
            <NamespaceSelector setNamespace={setNamespace} namespace={namespace} />
            <StatusSelector setStatus={setStatus} status={status} />
          </Row>
        </Container>
        <Query
          query={GET_TRANSLATION_TASKS}
          variables={{ namespace, status, priority, query, page, fallback: true }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            const translations = get(data, 'languages_translation_tasks.translations', []);
            const pagination = get(data, 'languages_translation_tasks.pagination', {});
            return (
              <>
                <Card>
                  {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
                  <TranslationsTable loading={loading} translations={translations} />
                </Card>
                {!loading && pagination && (
                  <Row>
                    <Column padding="20px">
                      <Pagination
                        total={pagination.total}
                        page={pagination.current_page}
                        pagesToShow={5}
                        perPage={pagination.per_page}
                        changePage={num => () => setPage(num)}
                        previous={<Icon icon="long-arrow-left" size="14px" styles={{ marginRight: '10px' }} />}
                        next={<Icon icon="long-arrow-right" size="14px" styles={{ marginLeft: '10px' }} />}
                      />
                    </Column>
                  </Row>
                )}
              </>
            );
          }}
        </Query>
      </Container>
    </>
  );
};

export default TranslationsPage;
