import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Mutation, Query } from 'react-apollo';
import get from 'lodash/get';
import styled from 'styled-components';

// UI
import Loader from '@lushdigital/ui/loader';
import Row from '@lushdigital/ui/row';
import withAlerts from '@lushdigital/ui/alerts/withAlerts';

// components
import ErrorPage from '../../components/errorPage';
import Error from '../../apollo/apollo.error';
import Form from './translation.form';
import { LockProvider } from '../../contexts/lock';

// config
import routes from '../../routes';
import { MANAGE_TRANSLATION, GET_TRANSLATION } from './translation.gql';
import { truncateString } from '../../helpers/helpers.strings';

const LoadingWrapper = styled(Row)`
  min-height: 400px;
  align-items: center;
`;

const TranslationPage = ({ match, insertAlert }) => {
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = React.useState(false);

  const languageId = match.params.language_id;
  const translationTaskId = match.params.translation_task_id;
  const isEdit = !!translationTaskId;

  const handleSubmit = (action, initialValues) => async values => {
    const variables = { ...values };
    variables.language = languageId;

    try {
      await action({
        variables: {
          ...variables,
          id: translationTaskId,
          interfaceLanguage: i18n.language,
          fromCopy: initialValues.copy
        }
      });

      insertAlert({
        type: 'success',
        message: t('SUCCESSFULLY_UPDATED', { name: truncateString(values.copy) }),
        time: 2
      });
      setRedirect(true);
    } catch (error) {
      insertAlert({
        type: 'error',
        message: get(error, 'graphQLErrors[0].extensions.message', error.message),
        time: 2
      });
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={routes.LANGUAGE_TRANSLATIONS.path.replace(':language', i18n.language).replace(':language_id', languageId)}
      />
    );
  }

  return (
    <LockProvider translationTaskId={translationTaskId} language={languageId}>
      <Mutation mutation={MANAGE_TRANSLATION}>
        {(action, { loading: saving }) => (
          <Query
            query={GET_TRANSLATION}
            variables={{ id: translationTaskId, language: languageId }}
            fetchPolicy="network-only"
          >
            {({ data, error, loading }) => {
              if (loading) {
                return (
                  <LoadingWrapper>
                    <Loader />
                  </LoadingWrapper>
                );
              }

              if (error) {
                return <ErrorPage error={error} />;
              }

              const initialValues = get(data, 'languages_translation', {});
              const values = { ...initialValues, ...{ priority: initialValues.priority === 'high' } };

              return (
                <>
                  {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
                  <Form
                    onSubmit={handleSubmit(action, initialValues)}
                    initialValues={values}
                    isEdit={isEdit}
                    loading={saving}
                  />
                </>
              );
            }}
          </Query>
        )}
      </Mutation>
    </LockProvider>
  );
};

TranslationPage.propTypes = {
  insertAlert: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired
};

export default withRouter(withAlerts(TranslationPage));
