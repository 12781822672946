import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { string, boolean } from 'yup';
import { Form as BambinoForm } from 'bambino-form';
import styled from 'styled-components';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';

// UI
import UIContainer from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import Column from '@lushdigital/ui/column';

// components
import Header from './translationTask.header';
import Modal from './translationTask.Modal';
import Input from '../../components/input';
import LockModal from '../../components/lockModal';
import Select from '../../components/select';

// config
import { GET_NAMESPACES } from '../translations/translations.gql';
import CheckboxField from '../../components/checkbox/checkbox';
import { lockContext } from '../../contexts/lock';

const Container = styled(UIContainer)`
  background: #ffffff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
`;

const Form = ({ onSubmit, initialValues, isEdit, loading }) => {
  const { t } = useTranslation();
  const { lock } = React.useContext(lockContext);
  const [modal, setModal] = React.useState(false);

  return (
    <>
      {isEdit && <LockModal lock={lock} />}
      <BambinoForm onSubmit={onSubmit} initialValues={initialValues}>
        <>
          <Header setModal={setModal} isEdit={isEdit} loading={loading} />
          <Container margin="40px auto" padding="30px 40px">
            <UIContainer maxWidth={isEdit ? '' : '740px'} margin="auto">
              <Row alignItems="stretch">
                <Column>
                  <Row>
                    <Column>
                      <Input
                        label={t('MASTER_COPY')}
                        placeholder={t('ENTER_MASTER_COPY')}
                        subtext={t('MASTER_COPY_SUBTEXT')}
                        name="master_copy"
                        maxLength={880}
                        shouldSanitize={false}
                        schema={string().required(t('TRANSLATION_IS_REQUIRED'))}
                        fullWidth
                        multiline
                      />
                    </Column>
                  </Row>
                </Column>
              </Row>
            </UIContainer>
          </Container>
        </>

        <Container margin="40px auto" padding="30px 40px">
          <h1>{t('INFORMATION')}</h1>
          <UIContainer maxWidth="740px" margin="auto">
            <Row alignItems="stretch">
              <Column alignItems="stretch">
                <Row>
                  <Column>
                    <Input
                      label={t('DESCRIPTION')}
                      placeholder={t('ENTER_DESCRIPTION')}
                      name="description"
                      schema={string().required(t('DESCRIPTION_IS_REQUIRED'))}
                      fullWidth
                      multiline
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Input
                      label={t('MACHINE_NAME')}
                      placeholder={t('ENTER_MACHINE_NAME')}
                      name="machine_name"
                      disabled={isEdit}
                      schema={string().required(t('MACHINE_NAME_IS_REQUIRED'))}
                      fullWidth
                    />
                  </Column>
                </Row>
                <Row>
                  <Column tiny={6}>
                    <Query query={GET_NAMESPACES}>
                      {({ data }) => {
                        const namespaces = get(data, 'languages_translation_namespaces', []);
                        return (
                          <Select
                            label={t('CONTEXT')}
                            placeholder={t('CONTEXT')}
                            schema={string().required(t('CONTEXT_IS_REQUIRED'))}
                            name="namespace"
                            options={namespaces}
                          />
                        );
                      }}
                    </Query>
                  </Column>
                </Row>
                <Row>
                  <Column tiny={6}>
                    <Select
                      label={t('TYPE')}
                      placeholder={t('TYPE')}
                      schema={string().required(t('TYPE_IS_REQUIRED'))}
                      disabled={isEdit}
                      name="type"
                      options={[{ id: 'ui', name: t('UI') }, { id: 'content', name: t('CONTENT') }]}
                    />
                  </Column>
                </Row>
              </Column>
            </Row>
          </UIContainer>
        </Container>

        <UIContainer padding="20px 0 50px 0">
          <CheckboxField label={t('MARK_AS_HIGH_PRIORITY')} schema={boolean()} name="priority" />
        </UIContainer>

        {modal && <Modal doSomething={onSubmit} saving={loading} onClose={() => setModal(false)} />}
      </BambinoForm>
    </>
  );
};

Form.defaultProps = {
  initialValues: {}
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired
};

export default withRouter(Form);
