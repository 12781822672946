import React from 'react';
import { useTranslation } from 'react-i18next';

// context
import { useSearchFilterContext } from '../../contexts/searchFilter';

// imports
import Filter from '../../components/filter';

// exported component
const StatusSelector = () => {
  const { t } = useTranslation();
  const { status, updateSearchFilterContext } = useSearchFilterContext();

  const options = [
    { id: 'todo', name: t('TODO') },
    { id: 'in_progress', name: t('IN_PROGRESS') },
    { id: 'completed', name: t('COMPLETED') }
  ];

  return (
    <Filter
      label={t('STATUS')}
      name="statuses"
      onChange={e => updateSearchFilterContext({ status: e.target.value })}
      options={options}
      value={status}
    />
  );
};
export default StatusSelector;
