import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import { string, boolean } from 'yup';
import { Form as BambinoForm } from 'bambino-form';
import styled from 'styled-components';
import get from 'lodash/get';
import find from 'lodash/find';
import { withRouter } from 'react-router-dom';

// UI
import UIContainer from '@lushdigital/ui/container';
import Row from '@lushdigital/ui/row';
import P from '@lushdigital/ui/p';
import Column from '@lushdigital/ui/column';
import Loader from '@lushdigital/ui/loader';
import Selector from '@lushdigital/ui/selector';
import Label from '@lushdigital/ui/label';

// components
import Header from './translation.header';
import Modal from './translation.Modal';
import Input from '../../components/input';
import LockModal from '../../components/lockModal';
import Select from '../../components/select';

// config
import { LANGUAGES_TRANSLATED_FOR_TASK } from './translation.gql';
import { GET_NAMESPACES } from '../translations/translations.gql';
import { GET_LANGUAGES } from '../languages/languages.gql';
import CheckboxField from '../../components/checkbox/checkbox';
import { lockContext } from '../../contexts/lock';

const Container = styled(UIContainer)`
  background: #ffffff;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
`;

const InlineLabel = styled(Label)`
  display: inline-block;
  width: auto;
  vertical-align: middle;
  margin: 0;
  padding-right: 5px;
  font-size: 14px;
`;

const Form = ({ onSubmit, initialValues, isEdit, match, loading }) => {
  const { t } = useTranslation();
  const { lock } = React.useContext(lockContext);
  const [translateFromId, setTranslateFromId] = React.useState('');
  const [modal, setModal] = React.useState(false);

  const languageId = match.params.language_id;
  const taskId = match.params.translation_task_id;

  return (
    <>
      {isEdit && <LockModal lock={lock} />}
      <BambinoForm onSubmit={onSubmit} initialValues={initialValues}>
        <Query query={GET_LANGUAGES}>
          {({ data, loading: languagesLoading }) => {
            const languages = get(data, 'languages', []);
            const language = languages.find(lang => lang.id === languageId) || {};

            return (
              <>
                <Header setModal={setModal} isEdit={isEdit} language={language} loading={loading || languagesLoading} />
                <Container margin="40px auto" padding="30px 40px">
                  <UIContainer maxWidth={isEdit ? '' : '740px'} margin="auto">
                    <Row alignItems="stretch">
                      {isEdit && (
                        <Column>
                          <Row>
                            <Column>
                              <div>{!languagesLoading && <></>}</div>
                              <div>
                                <Query query={LANGUAGES_TRANSLATED_FOR_TASK} variables={{ task_id: taskId }}>
                                  {({ error: transError, data: trans, loading: fetching }) => {
                                    if (fetching) return <Loader />;
                                    if (transError || Object.keys(trans).length === 0) {
                                      return null;
                                    }

                                    const translations = trans.languages_translated_for_task;
                                    const selectedTranslation = find(translations, {
                                      id: translateFromId
                                    });

                                    return (
                                      <>
                                        <InlineLabel>{t('COPY_IN')}</InlineLabel>
                                        <Selector
                                          styles={{ fontSize: '15px' }}
                                          name="translate from"
                                          hideLabel
                                          defaultValue=""
                                          value={translateFromId}
                                          onChange={e => setTranslateFromId(e.target.value)}
                                          options={translations.map(lang => ({
                                            value: lang.id,
                                            label: lang.name
                                          }))}
                                        />
                                        {selectedTranslation && <P padding="10px 0">{selectedTranslation.copy}</P>}
                                      </>
                                    );
                                  }}
                                </Query>
                              </div>
                            </Column>
                          </Row>
                        </Column>
                      )}
                      <Column>
                        <Row>
                          <Column>
                            {!languagesLoading && (
                              <Input
                                label={t('LANGUAGE_COPY', { language: language.name })}
                                placeholder={t('ENTER_LANGUAGE_COPY', { language: language.name })}
                                subtext={isEdit ? '' : t('LANGUAGE_COPY_SUBTEXT')}
                                name="copy"
                                schema={string().required(t('TRANSLATION_IS_REQUIRED'))}
                                fullWidth
                                shouldSanitize={false}
                                maxLength={880}
                                multiline
                              />
                            )}
                          </Column>
                        </Row>
                      </Column>
                    </Row>
                  </UIContainer>
                </Container>
              </>
            );
          }}
        </Query>

        <Container margin="40px auto" padding="30px 40px">
          <h1>{t('INFORMATION')}</h1>
          <UIContainer maxWidth="740px" margin="auto">
            <Row alignItems="stretch">
              <Column alignItems="stretch">
                <Row>
                  <Column>
                    <Input
                      label={t('DESCRIPTION')}
                      placeholder={t('ENTER_DESCRIPTION')}
                      name="description"
                      schema={string().required(t('DESCRIPTION_IS_REQUIRED'))}
                      fullWidth
                      multiline
                      disabled
                    />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Input
                      label={t('MACHINE_NAME')}
                      placeholder={t('ENTER_MACHINE_NAME')}
                      name="machine_name"
                      disabled
                      schema={string().required(t('MACHINE_NAME_IS_REQUIRED'))}
                      fullWidth
                    />
                  </Column>
                </Row>
                <Row>
                  <Column tiny={6}>
                    <Query query={GET_NAMESPACES}>
                      {({ data }) => {
                        const namespaces = get(data, 'languages_translation_namespaces', []);
                        return (
                          <Select
                            label={t('CONTEXT')}
                            placeholder={t('CONTEXT')}
                            schema={string().required(t('CONTEXT_IS_REQUIRED'))}
                            name="namespace"
                            options={namespaces}
                            disabled
                          />
                        );
                      }}
                    </Query>
                  </Column>
                </Row>
                <Row>
                  <Column tiny={6}>
                    <Select
                      label={t('TYPE')}
                      placeholder={t('TYPE')}
                      schema={string().required(t('TYPE_IS_REQUIRED'))}
                      name="type"
                      disabled
                      options={[{ id: 'ui', name: t('UI') }, { id: 'content', name: t('CONTENT') }]}
                    />
                  </Column>
                </Row>
              </Column>
            </Row>
          </UIContainer>
        </Container>

        <UIContainer padding="20px 0 50px 0">
          <CheckboxField label={t('MARK_AS_HIGH_PRIORITY')} schema={boolean()} name="priority" />
        </UIContainer>

        {modal && <Modal doSomething={onSubmit} saving={loading} onClose={() => setModal(false)} />}
      </BambinoForm>
    </>
  );
};

Form.defaultProps = {
  initialValues: {}
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.shape({ params: PropTypes.object.isRequired }).isRequired
};

export default withRouter(Form);
