import React from 'react';
import { useTranslation } from 'react-i18next';
import { Query } from 'react-apollo';
import get from 'lodash/get';

// contexts
import { useSearchFilterContext } from '../../contexts/searchFilter';

// local ui
import Error from '../../apollo/apollo.error';

// schema
import { GET_NAMESPACES } from './translations.gql';

// components
import Filter from '../../components/filter';

// exported component
const NamespaceSelector = () => {
  const { t } = useTranslation();
  const { namespace, updateSearchFilterContext } = useSearchFilterContext();

  return (
    <Query query={GET_NAMESPACES}>
      {({ loading, error, data }) => {
        const options = get(data, 'languages_translation_namespaces', []);
        return (
          <>
            {error && <Error alertTime={8} insertAlertProps={{ dismissible: false }} error={error} />}
            <Filter
              label={t('CONTEXT')}
              name="namespaces"
              onChange={e => updateSearchFilterContext({ namespace: e.target.value })}
              options={options}
              value={namespace}
              disabled={loading || error}
            />
          </>
        );
      }}
    </Query>
  );
};

export default NamespaceSelector;
