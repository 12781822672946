import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import Filter from '../../components/filter';

// exported component
const StatusSelector = ({ status, setStatus }) => {
  const { t } = useTranslation();

  const options = [{ id: 'enabled', name: t('ENABLED') }, { id: 'disabled', name: t('DISABLED') }];

  return (
    <Filter
      label={t('STATUS')}
      name="statuses"
      onChange={e => setStatus(e.target.value)}
      options={options}
      value={status}
    />
  );
};

StatusSelector.propTypes = {
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired
};

export default StatusSelector;
